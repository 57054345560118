/** @jsx jsx */
import { jsx, Text, Theme } from 'theme-ui';
import styled from '@emotion/styled';
import React, { ReactNode, useState } from 'react';
import {
  ICountriesToLanguages,
  ICountriesToLanguagesLanguage,
  ILocale,
  IPropsStyled,
  IPropsUiLibBase,
  TBlockContentBlocks,
  TCountryCode,
  TLanguageCode,
  TLocaleIdentifier,
  TTranslationFunction,
} from '@bemer/types';
import { defaultLocale } from '@bemer/middleware';
import { BemHeadline } from '../Headline';
import { BemBlockContent } from '../BlockContent';
import { BemButton } from '../Button';
import { BemIcon } from '../Icon';
import { BemSelectField } from '../InputFields';
import { BemBox } from '../Box';
import { BemGrid } from '../Grid';
import { BemOverlay } from '../Overlay';

export interface ILocaleSelectorData {
  title: string;
  copy?: TBlockContentBlocks;
  onSwitch: (localeIdentifier: TLocaleIdentifier) => void;
}

export interface IPropsBemLocaleSelector
  extends ILocaleSelectorData,
    IPropsUiLibBase {
  theme: Theme;
}

const StyledModal = styled.div`
  background-color: white;
  max-width: 534px;
  padding: 48px;
  border-radius: 22px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 2000;
`;
interface IModal {
  children: ReactNode;
}
const Modal = ({ children }: IModal): JSX.Element => (
  <StyledModal>{children}</StyledModal>
);

interface ICountrySelector {
  locale: ILocale;
  T: TTranslationFunction;
  countriesToLanguages: ICountriesToLanguages[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const CountrySelector = ({
  locale,
  countriesToLanguages,
  T,
  onChange = () => undefined,
}: ICountrySelector): JSX.Element => (
  <BemSelectField
    fieldName="country"
    id="country"
    label={T('field__country__label')}
    testId="field__country"
    value={locale.countryCode}
    onChange={onChange}
    options={countriesToLanguages.map(
      ({ countryCode, countryName }: ICountriesToLanguages) => ({
        value: countryCode,
        label: countryName,
      })
    )}
  />
);

interface ILanguageSelector {
  locale: ILocale;
  T: TTranslationFunction;
  availableLanguages: ICountriesToLanguagesLanguage[];
  selectedCountryCode: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const LanguageSelector = ({
  locale,
  availableLanguages,
  selectedCountryCode,
  T,
  onChange = () => undefined,
}: ILanguageSelector): JSX.Element => (
  <BemSelectField
    fieldName="language"
    id="language"
    testId="field__language"
    label={T('field__language__label')}
    value={
      locale.countryCode === selectedCountryCode
        ? locale.languageCode
        : undefined
    }
    onChange={onChange}
    options={availableLanguages.map(
      ({ languageName, languageCode }: ICountriesToLanguagesLanguage) => ({
        value: languageCode,
        label: languageName,
      })
    )}
  />
);

const StyledSelectedLocale = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }: IPropsStyled) => theme.colors?.navBarGray}
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
`;
interface ISelectedLocale {
  locale: ILocale;
  theme: Theme;
  onClick: () => void;
}
const SelectedLocale = ({
  locale,
  theme,
  onClick = () => undefined,
}: ISelectedLocale): JSX.Element => (
  <StyledSelectedLocale
    onClick={onClick}
    theme={theme}
    data-testid="locale__selector"
  >
    <Text
      sx={{
        mr: '8px',
        fontSize: '14px',
      }}
    >
      {locale.countryCode.toUpperCase()} | {locale.languageCode.toUpperCase()}
    </Text>
    <BemIcon iconName="chevronDown" />
  </StyledSelectedLocale>
);

const StyledCloseButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  display: inline;

  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const BemLocaleSelector = ({
  title,
  copy,
  locale = defaultLocale,
  countriesToLanguages = [],
  theme,
  T = () => '',
  onSwitch = () => undefined,
}: IPropsBemLocaleSelector): JSX.Element => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    locale.countryCode as TCountryCode
  );
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(
    locale.languageCode as TLanguageCode
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const selectedCountriesToLanguages = countriesToLanguages.find(
    (c: ICountriesToLanguages) => c.countryCode === selectedCountryCode
  );
  const availableLanguages = selectedCountriesToLanguages
    ? selectedCountriesToLanguages.languages
    : [];

  const handleSwitch = () => {
    const newLocaleIdentifier = `${selectedLanguageCode}_${selectedCountryCode}` as TLocaleIdentifier;
    setIsModalVisible(false);
    if (locale.id === newLocaleIdentifier) {
      return;
    }
    onSwitch(newLocaleIdentifier);
  };

  const handleCountrySelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const countryCode = e.target.value as TCountryCode;
    setSelectedCountryCode(countryCode);
    const selectedC2l = countriesToLanguages.find(
      (c: ICountriesToLanguages) => c.countryCode === countryCode
    );
    if (!selectedC2l) {
      return;
    }
    setSelectedLanguageCode(selectedC2l.defaultLanguageCode);
  };

  return (
    <div>
      <SelectedLocale
        locale={locale}
        theme={theme}
        onClick={() => setIsModalVisible(true)}
      />
      {isModalVisible && (
        <BemOverlay>
          <Modal>
            <StyledCloseButton onClick={() => setIsModalVisible(false)}>
              <BemIcon
                iconName="times"
                rawSx={{
                  transform: 'scale(1.75)',
                }}
              />
            </StyledCloseButton>
            <BemHeadline rank="h3" isCentered rawSx={{ mb: '50px' }}>
              {title}
            </BemHeadline>
            {copy && <BemBlockContent blocks={copy} rawSx={{ mb: '50px' }} />}

            <BemBox rawSx={{ mb: '50px' }}>
              <BemBox rawSx={{ mb: '20px' }}>
                <CountrySelector
                  locale={locale}
                  T={T}
                  countriesToLanguages={countriesToLanguages}
                  onChange={handleCountrySelection}
                />
              </BemBox>

              <LanguageSelector
                locale={locale}
                T={T}
                availableLanguages={availableLanguages}
                selectedCountryCode={selectedCountryCode}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectedLanguageCode(e.target.value as TLanguageCode)
                }
              />
            </BemBox>
            <BemGrid gap="20px" columns="1fr 1fr">
              <BemButton
                variant="secondary"
                onClick={() => setIsModalVisible(false)}
                testId="button__cancel"
              >
                {T('button__cancel__label')}
              </BemButton>
              <BemButton
                variant="primary"
                onClick={handleSwitch}
                testId="button__switch"
              >
                {T('button__switch__label')}
              </BemButton>
            </BemGrid>
          </Modal>
        </BemOverlay>
      )}
    </div>
  );
};
