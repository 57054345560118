/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase, TBlockContentBlocks } from '@bemer/types';
import { BemSection } from '../../components/Section';
import { BemHeadline } from '../../components/Headline';
import { BemBlockContent } from '../../components/BlockContent';
import { BemGrid } from '../../components/Grid';
import { BemImage, IPropsSanityImage } from '../../components/Image/BemImage';

export interface IProductBenefit {
  title?: string;
  image: IPropsSanityImage;
  copy: TBlockContentBlocks;
}

const StyledProductBenefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  text-align: center;
`;

export const ProductBenefit = ({
  image,
  title,
  copy,
}: IProductBenefit): JSX.Element => {
  return (
    <StyledProductBenefit>
      <BemImage
        image={image}
        rawSx={{
          width: '112px',
          borderRadius: '100%',
          mb: '25px',
          overflow: 'hidden',
        }}
      />
      {title && (
        <BemHeadline rank="h4" rawSx={{ mb: '2px' }} isCentered>
          {title}
        </BemHeadline>
      )}
      <BemBlockContent blocks={copy} />
    </StyledProductBenefit>
  );
};

export interface IPropsBemModuleProductBenefits extends IPropsUiLibBase {
  productBenefits: IProductBenefit[];
}
export const BemModuleProductBenefits = ({
  productBenefits,
  rawSx,
}: IPropsBemModuleProductBenefits): JSX.Element => {
  return (
    <BemSection rawSx={rawSx} isCentered>
      <BemGrid
        columns={[1, `repeat(${productBenefits.length}, minmax(0, 1fr))`]}
      >
        {productBenefits.map(({ image, title, copy }: IProductBenefit) => (
          <ProductBenefit
            key={image?.asset?.fluid?.src + title}
            image={image}
            title={title}
            copy={copy}
          />
        ))}
      </BemGrid>
    </BemSection>
  );
};
