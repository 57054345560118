import { BemFooter } from '@bemer/ui-library';
import { graphql, StaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import {
  IGraphqlJsonDataVersionStats,
  IGraphqlSiteSettings,
} from '@bemer/types';

import TranslationContext from '../../context/translationContext';

interface ISanitySiteSetting {
  node: IGraphqlSiteSettings;
}

interface IJsonDataVersionStats {
  node: IGraphqlJsonDataVersionStats;
}

const index = (): JSX.Element => {
  const { locale } = useContext(TranslationContext);

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          versionStatsData: allDataJson {
            edges {
              node {
                buildDate
                version
                versionDomainAndNumber
              }
            }
          }

          siteSettings: allSanitySiteSettings {
            edges {
              node {
                language {
                  localeIdentifier
                }
                footer {
                  text
                  linkLists {
                    links {
                      ... on SanityLink {
                        key: _key
                        type: _type
                        label
                        url
                      }
                      ... on SanityInternalLink {
                        key: _key
                        type: _type
                        label
                        target
                        reference {
                          slug {
                            current
                          }
                        }
                      }
                    }
                    title: label
                  }
                }
              }
            }
          }
        }
      `}
      render={({ siteSettings: { edges }, versionStatsData }) => {
        const {
          footer: { linkLists, text },
        } = edges.filter(
          (footer: ISanitySiteSetting) =>
            footer?.node?.language?.localeIdentifier === locale.id
        )[0]?.node;

        const versionNumber = versionStatsData.edges.filter(
          (edge: IJsonDataVersionStats) => !!edge.node.versionDomainAndNumber
        )[0]?.node.versionDomainAndNumber;

        return (
          <BemFooter
            copyText={text}
            linkLists={linkLists}
            versionNumber={versionNumber}
          />
        );
      }}
    />
  );
};

export default index;
