/** @jsx jsx */
import { jsx } from 'theme-ui';
import Img from 'gatsby-image';
import React, { ForwardedRef } from 'react';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsSanityImage {
  asset: {
    fluid: {
      base64: string;
      aspectRatio: number;
      src: string;
      srcSet: string;
      sizes: string;
    };
  };
}

export interface IPropsBemImage extends IPropsUiLibBase {
  image: IPropsSanityImage;
}

export const BemImage = React.forwardRef(
  (
    { rawSx, image }: IPropsBemImage,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const fluidImage = image?.asset?.fluid;

    return (
      <div ref={ref} sx={rawSx}>
        {fluidImage && <Img fluid={fluidImage} />}
      </div>
    );
  }
);
