/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemPill extends IPropsUiLibBase {
  backgroundColor?: string;
  color?: string;
}

const StyledPill = styled(Box)`
  display: inline-block;
  font-weight: bold;
  font-size: 9px;
  padding: 4px 8px 4px 8px;
  border-radius: 20px;
`;

export const BemPill = ({
  rawSx,
  backgroundColor = 'black',
  color = 'white',
  children,
}: IPropsBemPill): JSX.Element => {
  return (
    <StyledPill sx={{ backgroundColor, color, ...rawSx }}>
      {children}
    </StyledPill>
  );
};
