import { ILocale, ISelectOption } from '@bemer/types';
import { defaultLocale } from './locale';

interface IPossibleBirthDateValues {
  years: ISelectOption[];
  months: ISelectOption[];
  days: ISelectOption[];
}
export const getPossibleBirthDateValues = (
  locale: ILocale
): IPossibleBirthDateValues => {
  const MAX_AGE = 100;
  const MIN_AGE = 18;
  const currentYear = new Date().getFullYear();

  const days = [...Array(31)].map((_, i: number) => {
    const day = String(i + 1).padStart(2, '0');
    return {
      id: day,
      value: day,
      label: day,
    };
  });
  const years = [...Array(MAX_AGE - MIN_AGE)].map((_, i: number) => ({
    id: currentYear - i - MIN_AGE,
    value: currentYear - i - MIN_AGE,
    label: currentYear - i - MIN_AGE,
  }));

  const months = [...Array(12)].map((_, i: number) => {
    const formatter = new Intl.DateTimeFormat(
      (locale || defaultLocale).bcp47Id,
      {
        month: 'long',
      }
    );
    const month = String(i + 1).padStart(2, '0');
    return {
      id: month,
      value: month,
      label: formatter.format(new Date(2020, i, 15)),
    };
  });

  return {
    days,
    months,
    years,
  };
};

export const sortBirthdayFieldsForLocale = () => {
  return true;
};
