/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import {
  IPropsUiLibBase,
  IPropsStyled,
  IPartnerFinderData,
} from '@bemer/types';
import { BemText } from '../Text';
import { BemIcon } from '../Icon';
import { BemCaption } from '../Caption';
import { BemLink } from '../Link';

export interface IPropsBemPersonCard extends IPropsUiLibBase {
  data: IPartnerFinderData;
}

const Wrapper = styled.div`
  border: 3px solid ${({ theme }: IPropsStyled) => theme.colors?.primary};
  padding: 30px;
  margin-bottom: 16px;
  display: flex;

  a {
    color: ${({ theme }: IPropsStyled) => theme.colors?.primary};
    text-decoration: none;
  }
`;

const AvatarWrapper = styled.div`
  align-items: center;
  display: flex;
  background: ${({ theme }: IPropsStyled) => theme.colors?.gray};
  border-radius: 50%;
  flex: 0 0 120px;
  height: 120px;
  justify-content: center;
  margin-right: 30px;
  width: 120px;

  svg {
    color: ${({ theme }: IPropsStyled) => theme.colors?.lightBlueGray};
    width: 60%;
    height: 60%;
  }
`;

export const BemPersonCard = ({
  rawSx,
  data,
}: IPropsBemPersonCard): JSX.Element => {
  const context = useThemeUI();
  const { theme } = context;

  const {
    finderData: {
      partnerFinderName,
      partnerFinderCompany,
      partnerFinderAddress,
      partnerFinderPhone,
    },
  } = data;

  return (
    <Wrapper sx={rawSx} theme={theme}>
      <AvatarWrapper theme={theme}>
        <BemIcon
          iconName="user"
          rawSx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </AvatarWrapper>
      <div>
        {partnerFinderName && (
          <BemCaption isBold type="small" rawSx={{ mb: '8px' }}>
            {partnerFinderName}
          </BemCaption>
        )}
        {partnerFinderCompany && (
          <BemText isSmall sx={{ mb: '8px' }}>
            {partnerFinderCompany}
          </BemText>
        )}
        {partnerFinderAddress && (
          <BemText isSmall sx={{ mb: '8px' }}>
            {partnerFinderAddress}
          </BemText>
        )}
        {partnerFinderPhone && (
          <BemLink to={`tel:${partnerFinderPhone}`}>
            {partnerFinderPhone}
          </BemLink>
        )}
      </div>
    </Wrapper>
  );
};
