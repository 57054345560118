import {
  ICountriesToLanguages,
  ILocale,
  IGraphqlSupportedLanguage,
  TCountryCode,
  TLanguageCode,
  TLocaleIdentifier,
  TLocaleIdentifierBcp47,
  TCurrency,
} from '@bemer/types';

export const getCountryCodeFromLocale = (
  locale: TLocaleIdentifier
): TCountryCode => locale.split('_')[1] as TCountryCode;

export const getLanguageCodeFromLocale = (
  locale: TLocaleIdentifier
): TLanguageCode => locale.split('_')[0] as TLanguageCode;

export const buildLocale = (
  localeId: TLocaleIdentifier,
  currency: TCurrency
): ILocale => {
  return {
    id: localeId,
    bcp47Id: localeId.replace('_', '-') as TLocaleIdentifierBcp47,
    languageCode: getLanguageCodeFromLocale(localeId),
    countryCode: getCountryCodeFromLocale(localeId),
    currency,
  };
};

export const buildCountriesToLanguages = (
  supportedLanguages: IGraphqlSupportedLanguage[]
): ICountriesToLanguages[] => {
  console.log('TODO: buildCountriesToLanguages needs only to run once');
  const countriesToLanguages: ICountriesToLanguages[] = [];
  supportedLanguages.forEach((s: IGraphqlSupportedLanguage) => {
    const locale = buildLocale(s.localeIdentifier, s.currency);

    let c2l = countriesToLanguages.find(
      (c: ICountriesToLanguages) => c.countryCode === locale.countryCode
    );

    if (!c2l) {
      c2l = {
        countryCode: locale.countryCode,
        countryName: s.country,
        defaultLanguageCode: locale.languageCode,
        languages: [],
        currency: s.currency,
      };
      countriesToLanguages.push(c2l);
    }

    c2l.languages.push({
      languageCode: locale.languageCode,
      languageName: s.language,
      locale,
    });
  });
  return countriesToLanguages;
};

export const defaultLocale = buildLocale('de_DE', 'EUR');
