import React from 'react';
import { useThemeUI } from 'theme-ui';
import { IPropsStyled, IPropsUiLibBase } from '@bemer/types';
import styled from '@emotion/styled';
import { BemCaption } from '../Caption';
import { BemText } from '../Text';

export interface IPropsBemErrorBox extends IPropsUiLibBase {
  headline?: string;
  message: string;
}

const StyledErrorBox = styled.div`
  color: ${({ theme }: IPropsStyled) => theme.colors?.text};
  background: ${({ theme }: IPropsStyled) => theme.colors?.dangerLightest};
  border: none;

  margin: 0 0 20px;
  padding: 26px 24px 30px;
`;

export const BemErrorBox = ({
  headline,
  rawSx,
  message,
}: IPropsBemErrorBox): JSX.Element => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <StyledErrorBox theme={theme} sx={rawSx}>
      <BemCaption type="mini">{headline}</BemCaption>
      <BemText isSmall>{message}</BemText>
    </StyledErrorBox>
  );
};
