/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import { ReactNode, useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { IPropsUiLibBase } from '@bemer/types';
import styled from '@emotion/styled';
import { BemSection } from '../../components/Section';
import { BemText } from '../../components/Text';
import { BemFlex } from '../../components/Flex';
import { BemBox, BemImage } from '../../components';
import { IPropsSanityImage } from '../../components/Image/BemImage';

const ProductRow = styled(Flex)`
  width: 100%;
  left: 0;
  right: 0;
  padding: 14px;
  align-items: center;
  justify-content: space-between;
  background: white;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 6px 12px 0 rgba(70, 70, 70, 0.12);
`;

export interface IPropsBemModuleProductHero extends IPropsUiLibBase {
  image: IPropsSanityImage;
  name: string;
  price?: string;
  button: ReactNode;
  pricePrefixLabel?: string | null;
  priceSuffixLabel?: string | null;
  topOffset?: number;
}

export const BemModuleProductHero = ({
  image,
  name,
  price,
  pricePrefixLabel,
  priceSuffixLabel,
  button,
  rawSx,
  topOffset = 0,
}: IPropsBemModuleProductHero): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `${topOffset * -1}px`,
  });

  const productRowRef = useRef<HTMLDivElement>(null);

  const [productRowHeight, setProductRowHeight] = useState(0);

  useEffect(() => {
    const productRow = productRowRef?.current;

    const setNewHeight = () => {
      if (productRow) {
        setProductRowHeight(productRow?.clientHeight || 0);
      }
    };

    setNewHeight();

    window.addEventListener('resize', setNewHeight);

    return () => window.removeEventListener('resize', setNewHeight);
  }, [productRowRef]);

  const label = [
    pricePrefixLabel || null,
    price || null,
    priceSuffixLabel || null,
  ]
    .filter((text) => Boolean(text))
    .join(' ');

  return (
    <BemSection
      isFullWidth
      rawSx={{
        position: 'relative',
        zIndex: 1,
        ...rawSx,
      }}
    >
      <BemBox
        rawSx={{
          position: 'relative',
          width: '100%',
          maxWidth: '1280px',
          marginBottom: inView ? 0 : productRowHeight,
        }}
      >
        <BemImage
          ref={ref}
          image={image}
          rawSx={{ display: 'block', maxWidth: '1280px', width: '100%' }}
        />
      </BemBox>
      <ProductRow
        ref={productRowRef}
        sx={{
          position: inView ? 'relative' : 'fixed',
          top: inView ? 0 : `${topOffset}px`,
        }}
      >
        <BemText isBold>{name}</BemText>
        <BemFlex
          rawSx={{
            alignItems: 'center',
          }}
        >
          {price ? (
            <BemText isBold rawSx={{ mr: '20px' }}>
              {label}
            </BemText>
          ) : null}

          {button}
        </BemFlex>
      </ProductRow>
    </BemSection>
  );
};
