import React from 'react';
import { Heading } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';
import styled from '@emotion/styled';

export type TBemHeadlineRank = 'h1' | 'h2' | 'h3' | 'h4';

export interface IPropsBemHeadline extends IPropsUiLibBase {
  rank?: TBemHeadlineRank;
  isCentered?: boolean;
}

const StyledHeading = styled(Heading)<IPropsBemHeadline>`
  text-align: ${(props) => (props.isCentered ? 'center' : 'left')};
`;

export const BemHeadline = ({
  rank = 'h1',
  isCentered = false,
  rawSx,
  children,
}: IPropsBemHeadline): JSX.Element => (
  <StyledHeading
    as={rank}
    variant={`text.heading.${rank}`}
    sx={{
      ...rawSx,
    }}
    isCentered={isCentered}
  >
    {children}
  </StyledHeading>
);
