import { validateEmailForOrderABemerTrial } from '@bemer/middleware';
import React, { useState } from 'react';
import { BemSpinner } from '../Spinner';
import { BemInputField, IPropsBemInputField } from './BemInputField';

interface IPropsBemEmailField extends IPropsBemInputField {
  checkBackend?: boolean;
}

const BemEmailField = ({
  checkBackend = false,
  validationRules = {},
  ...props
}: IPropsBemEmailField) => {
  const { T = () => '' } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  let backendValdidation = {};
  if (checkBackend) {
    backendValdidation = {
      validate: async (value: string) => {
        setShowSpinner(true);
        const result =
          (await validateEmailForOrderABemerTrial(value)) ||
          T('error__email_validation');
        setShowSpinner(false);
        return result;
      },
    };
  }

  return (
    <BemInputField
      {...props}
      suffix={showSpinner ? <BemSpinner size="small" /> : null}
      validationRules={{ ...validationRules, ...backendValdidation }}
    />
  );
};

export { BemEmailField };
