import React from 'react';
import { Text } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';

export type TBemCaptionType = 'big' | 'small' | 'mini';

export interface IPropsBemCaption extends IPropsUiLibBase {
  type?: TBemCaptionType;
  isBold?: boolean;
}

export const BemCaption = ({
  type = 'big',
  rawSx,
  children,
  isBold,
}: IPropsBemCaption): JSX.Element => (
  <Text
    variant={`text.caption.${type}`}
    sx={{
      fontWeight: isBold ? 'bold' : 'normal',
      ...rawSx,
    }}
  >
    {children}
  </Text>
);
