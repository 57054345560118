/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemPage extends IPropsUiLibBase {}

const StyledPage = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

export const BemPage = ({ rawSx, children }: IPropsBemPage): JSX.Element => (
  <StyledPage sx={rawSx}>{children}</StyledPage>
);
