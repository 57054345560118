/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase, TBlockContentBlocks } from '@bemer/types';
import { BemBlockContent } from '../BlockContent';
import { BemText } from '../Text/BemText';
import { BemImage, IPropsSanityImage } from '../Image/BemImage';

export interface IPropsBemCard extends IPropsUiLibBase {
  image: IPropsSanityImage;
  headline: string;
  copy: TBlockContentBlocks;
}

const StyledCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 130px;

  padding: 18px 24px 34px;
  border-radius: 5px;
  box-shadow: 0 3px 13px -4px rgba(47, 47, 47, 0.2),
    0 0 3px 0 rgba(139, 139, 139, 0.07);
`;

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StyledImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
`;

export const BemCard = ({
  image,
  headline,
  copy,
  rawSx,
}: IPropsBemCard): JSX.Element => {
  console.log('image ', image);

  return (
    <StyledCard sx={rawSx}>
      <StyledHeader>
        <StyledImageWrapper>
          <BemImage image={image} />
        </StyledImageWrapper>
        <BemText isBold>{headline}</BemText>
      </StyledHeader>
      <BemBlockContent blocks={copy} />
    </StyledCard>
  );
};
