import React from 'react';

import {
  FaTimes,
  FaCheck,
  FaChevronDown,
  FaArrowLeft,
  FaInfoCircle,
  FaSearch,
  FaUser,
} from 'react-icons/fa';

export const getIconComp = (iconName: string): JSX.Element | null => {
  switch (iconName) {
    case 'times':
      return <FaTimes />;
    case 'check':
      return <FaCheck />;
    case 'chevronDown':
      return <FaChevronDown />;
    case 'arrowLeft':
      return <FaArrowLeft />;
    case 'infoCircle':
      return <FaInfoCircle />;
    case 'search':
      return <FaSearch />;
    case 'user':
      return <FaUser />;
    default:
      return null;
  }
};
