/** @jsx jsx */
import { jsx } from 'theme-ui';
import { UseFormMethods } from 'react-hook-form/dist/types';
import { ValidationRules } from 'react-hook-form/dist/types/validator';
import { IPropsUiLibBase } from '@bemer/types';
import styled from '@emotion/styled';
import { BemFlex } from '../Flex';

export interface IBemRadioFieldOption {
  id?: string;
  label: string;
  value: string;
  isSelected?: boolean;
}

export interface IPropsBemRadioField extends IPropsUiLibBase {
  id?: string;
  fieldName: string;
  options: IBemRadioFieldOption[];
  formMethods: UseFormMethods;
  validationRules?: ValidationRules;
}

const Wrapper = styled(BemFlex)`
  && {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const InputWrapper = styled(BemFlex)`
  width: 100%;
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked ~ label {
    color: rgb(234, 239, 251);
    background: rgb(86, 126, 228);
  }
`;

const StyledLabel = styled.label`
  display: flex;
  padding: 14.5px 0;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: rgb(86, 126, 228);
  background-color: rgb(234, 239, 251);
`;

export const BemRadioField = ({
  id,
  fieldName,
  options,
  formMethods,
  validationRules = {},
  rawSx,
}: IPropsBemRadioField): JSX.Element | null => (
  <Wrapper
    id={id || fieldName}
    rawSx={{
      // mb: '16px',
      ...rawSx,
    }}
  >
    {options.map((option: IBemRadioFieldOption) => (
      <InputWrapper key={option.id || option.value}>
        <StyledInput
          type="radio"
          id={option.id || option.value}
          name={fieldName}
          value={option.value}
          defaultChecked={option.isSelected}
          ref={formMethods.register(validationRules)}
        />
        <StyledLabel htmlFor={option.id || option.value}>
          {option.label}
        </StyledLabel>
      </InputWrapper>
    ))}
  </Wrapper>
);
