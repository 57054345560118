import { validateTaxIdForOrderABemerTrial } from '@bemer/middleware';
import { ILocale } from '@bemer/types';
import React, { useState } from 'react';
import { BemSpinner } from '../Spinner';
import { BemInputField, IPropsBemInputField } from './BemInputField';

interface IPropsBemEmailField extends IPropsBemInputField {
  locale: ILocale;
  checkBackend?: boolean;
}
const BemTaxIdField = ({
  checkBackend = false,
  validationRules = {},
  ...props
}: IPropsBemEmailField) => {
  const { T = () => '', formMethods, fieldName, locale } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  let backendValdidation = {};
  if (checkBackend) {
    backendValdidation = {
      validate: async (value: string) => {
        const isOptional = !Object.keys(validationRules).includes('required');

        // if no value was provided, the field is valid because it is optional
        if (isOptional && !value) {
          return true;
        }

        setShowSpinner(true);
        const result = await validateTaxIdForOrderABemerTrial(value, locale);

        formMethods.setValue(fieldName, result.taxId, {
          shouldValidate: false,
        });

        setShowSpinner(false);
        return result.valid || T('error__taxId_validation');
      },
    };
  }

  return (
    <BemInputField
      {...props}
      suffix={showSpinner ? <BemSpinner size="small" /> : null}
      validationRules={{ ...validationRules, ...backendValdidation }}
    />
  );
};

export { BemTaxIdField };
