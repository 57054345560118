/** @jsx jsx */
import { jsx } from 'theme-ui';

import { IPropsUiLibBase } from '@bemer/types';
import { BemSection } from '../../components/Section';
import { BemHeadline } from '../../components/Headline';
import { BemCaption } from '../../components/Caption';

export interface IPropsBemModuleTitle extends IPropsUiLibBase {
  title: string;
  subtitle?: string;
}

export const BemModuleTitle = ({
  title,
  subtitle,
  rawSx,
}: IPropsBemModuleTitle): JSX.Element => {
  return (
    <BemSection rawSx={rawSx} isCentered>
      <BemHeadline rank="h1" rawSx={{ mb: '27px' }} isCentered>
        {title}
      </BemHeadline>
      {subtitle && (
        <BemCaption rawSx={{ maxWidth: '820px', textAlign: 'center' }}>
          {subtitle}
        </BemCaption>
      )}
    </BemSection>
  );
};
