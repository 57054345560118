/** @jsx jsx */
import { IPropsUiLibBase, TBlockContentBlocks } from '@bemer/types';
import { jsx } from 'theme-ui';
import { BemBlockContent, BemBox, BemHeadline } from '../../components';

export interface ISection {
  title: string;
  copy: TBlockContentBlocks;
  key: string;
}

export interface IPropsBemModuleTextWithToc extends IPropsUiLibBase {
  title: string;
  sections: ISection[];
}

export const BemModuleTextWithToc = ({
  title,
  sections,
}: IPropsBemModuleTextWithToc): JSX.Element => {
  return (
    <BemBox
      rawSx={{
        m: ['0 20px', '0 95px'],
        py: ['50px', '100px'],
      }}
    >
      <BemHeadline rank="h1" rawSx={{ mb: '6px' }}>
        {title}
      </BemHeadline>

      {sections.map((section) => (
        <BemBlockContent
          rawSx={{ pb: '30px' }}
          key={section.key}
          blocks={section.copy}
        />
      ))}
    </BemBox>
  );
};
