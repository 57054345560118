import React from 'react';
import { Text } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemText extends IPropsUiLibBase {
  isBold?: boolean;
  isSmall?: boolean;
  className?: string;
  as?: React.ElementType;
}

export const BemText = ({
  as = 'p',
  isBold,
  isSmall,
  className,
  rawSx,
  children,
}: IPropsBemText): JSX.Element => (
  <Text
    as={as}
    className={className}
    variant={isSmall ? 'small' : 'default'}
    sx={{
      fontWeight: isBold ? 'bold' : 'normal',
      ...rawSx,
    }}
  >
    {children}
  </Text>
);
