/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IPartnerFinderData, IPropsUiLibBase } from '@bemer/types';
import { fetchPartnerSuggestions } from '@bemer/middleware';
import { BemAutocompleteField } from '../InputFields/BemAutocompleteField';
import {
  getIsPartnerGivenViaUrlHandle,
  putPartnerFinderDataIntoStore,
  removePartnerFinderDataIntoStore,
} from '../../../../middleware/src/bemerApi/PartnerService';

import { BemText } from '../Text';
import { BemButton } from '../Button';
import { BemPersonCard } from '../PersonCard';
import { IPropsBemInputField } from '../InputFields';

export interface IPropsBemPartnerFinder extends IPropsBemInputField {
  initialPartnerData?: IPartnerFinderData;
  onSelect?: (distributor: IPartnerFinderData | undefined) => void;
}

const StyledPartnerFinder = styled.div``;

const sharedHolderHeaderStyle = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const StyledSelectedDistributorHolderHeader = styled.div`
  ${sharedHolderHeaderStyle}
`;

interface IPropsSelectedDistributorHolder extends IPropsUiLibBase {
  isVisible: boolean;
  onReset: (e: React.MouseEvent) => void;
  isPartnerGivenViaUrlHandle: boolean;
}

type TSelectedDistributorName = IPartnerFinderData | undefined;

const SelectedDistributorHolder = ({
  isVisible = false,
  onReset = (_e) => undefined,
  T = () => '',
  isPartnerGivenViaUrlHandle = false,
  children,
}: IPropsSelectedDistributorHolder) => {
  return (
    <div>
      <StyledSelectedDistributorHolderHeader>
        {isVisible ? (
          <React.Fragment>
            <BemText isBold rawSx={{ mb: '16px', mt: '40px' }}>
              {T('fieldset_headline__bemer_partner_selected')}
            </BemText>
            {!isPartnerGivenViaUrlHandle && (
              <BemButton
                variant="link"
                onClick={onReset}
                testId="fieldset_headline__wrong_bemer_partner"
              >
                {T('fieldset_headline__wrong_bemer_partner')}
              </BemButton>
            )}
          </React.Fragment>
        ) : (
          <BemText isBold rawSx={{ mb: '16px', mt: '40px' }}>
            {T('fieldset_headline__bemer_partner')}
          </BemText>
        )}
      </StyledSelectedDistributorHolderHeader>
      {isVisible && children}
    </div>
  );
};

export const BemPartnerFinder = ({
  rawSx,
  T = () => '',
  fieldName,
  label = T('field__distributorName__label'),
  placeholder = T('field__distributorName__placeholder', false),
  datalistId = `${fieldName}_datalist`,
  initialPartnerData,
  onSelect = () => undefined,
  formMethods,
}: IPropsBemPartnerFinder): JSX.Element => {
  const [distributorsList, setDistrubutorsList] = useState<
    IPartnerFinderData[]
  >([]);
  const [isDistrubutorListLoading, setIsDistrubutorListLoading] = useState(
    false
  );
  const [selectedDistributor, setSelectedDistributor] = useState(
    initialPartnerData
  );
  const isPartnerGivenViaUrlHandle = getIsPartnerGivenViaUrlHandle();

  const updateDistributorAutocompleteList = (data: IPartnerFinderData[]) => {
    setDistrubutorsList(data);
    setIsDistrubutorListLoading(false);
  };

  const distributorSuggestion = formMethods?.watch(fieldName);

  useEffect(() => {
    if (selectedDistributor) return;

    if (distributorSuggestion && distributorSuggestion !== '') {
      setIsDistrubutorListLoading(true);
    }

    const selectedDistributorName: TSelectedDistributorName = distributorsList.find(
      ({ partnerData }) => distributorSuggestion === partnerData.name
    );

    setSelectedDistributor(selectedDistributorName);

    if (!selectedDistributorName) {
      fetchPartnerSuggestions(distributorSuggestion).then(
        updateDistributorAutocompleteList
      );
    } else {
      setIsDistrubutorListLoading(false);
    }
  }, [distributorSuggestion]);

  useEffect(() => {
    if (!selectedDistributor) {
      removePartnerFinderDataIntoStore();
    } else {
      putPartnerFinderDataIntoStore(selectedDistributor);
    }

    if (onSelect) {
      onSelect(selectedDistributor);
    }
  }, [selectedDistributor]);

  const resetSelectedDistributor = (e: React.MouseEvent): void => {
    e.preventDefault();
    setSelectedDistributor(undefined);
    formMethods.setValue(fieldName, null);
  };

  const formattedDistributorsList = distributorsList.map((distributorData) => {
    return {
      key: distributorData?.partnerData?.customerNo,
      value: distributorData?.partnerData?.name,
    };
  });

  return (
    <StyledPartnerFinder sx={rawSx}>
      <SelectedDistributorHolder
        isVisible={!!selectedDistributor}
        T={T}
        isPartnerGivenViaUrlHandle={!!isPartnerGivenViaUrlHandle}
        onReset={resetSelectedDistributor}
      >
        {selectedDistributor && <BemPersonCard data={selectedDistributor} />}
      </SelectedDistributorHolder>
      <BemAutocompleteField
        fieldName={fieldName}
        isVisible={!selectedDistributor}
        label={label}
        placeholder={placeholder}
        testId="input__autocomplete__field"
        validationRules={{
          required: T('error__required'),
        }}
        isDataFetching={isDistrubutorListLoading}
        dataSource={formattedDistributorsList}
        datalistId={datalistId}
        T={T}
        formMethods={formMethods}
      />
    </StyledPartnerFinder>
  );
};
