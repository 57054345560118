/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import React from 'react';

import { IPropsUiLibBase, IProduct } from '@bemer/types';
import { BemFlex } from '../Flex';
import { BemText } from '../Text';
import { BemBox } from '../Box';

export interface IPropsBemProductPrice extends IPropsUiLibBase {
  selectedProduct: IProduct;
  products: IProduct[];
}

const StyledProductPriceBox = styled(BemBox)``;

const StyledProductPriceLabel = styled(BemText)`
  flex: 0 1 auto;
  flex-basis: 60%;
`;

const StyledProductPricePrice = styled(BemText)`
  flex: 1 1 auto;
  text-align: right;
`;

export const BemProductPrice = ({
  rawSx,
  selectedProduct,
  products,
  locale,
  T = () => '',
}: IPropsBemProductPrice): JSX.Element => {
  const feeProduct =
    products.filter(({ productValue }) => productValue === 'fee')[0] ||
    undefined;

  const getLocalizedAmount = (amount: number, currency = 'EUR') =>
    new Intl.NumberFormat(locale?.bcp47Id, {
      style: 'currency',
      currency: currency || 'EUR',
    }).format(amount);

  return (
    <StyledProductPriceBox sx={{ backgroundColor: 'paleGray', p: 3, ...rawSx }}>
      <BemFlex rawSx={{ flexWrap: 'wrap' }}>
        {selectedProduct ? (
          <React.Fragment>
            <StyledProductPriceLabel isSmall rawSx={{ mr: 2 }}>
              {selectedProduct?.productName}
            </StyledProductPriceLabel>

            <StyledProductPricePrice isSmall isBold>
              {getLocalizedAmount(
                selectedProduct?.productPrice,
                locale?.currency
              )}
            </StyledProductPricePrice>
          </React.Fragment>
        ) : null}

        {feeProduct ? (
          <React.Fragment>
            <StyledProductPriceLabel isSmall rawSx={{ mr: 2 }}>
              {feeProduct?.productName}
            </StyledProductPriceLabel>

            <StyledProductPricePrice isSmall isBold>
              {getLocalizedAmount(feeProduct?.productPrice, locale?.currency)}
            </StyledProductPricePrice>
          </React.Fragment>
        ) : null}

        <StyledProductPriceLabel isSmall rawSx={{ mt: 3, mr: 2 }}>
          {T('productPrice_label__total_amount')}
        </StyledProductPriceLabel>

        <StyledProductPricePrice isSmall isBold rawSx={{ mt: 3 }}>
          {getLocalizedAmount(
            (feeProduct?.productPrice || 0) +
              (selectedProduct?.productPrice || 0),
            locale?.currency
          )}
        </StyledProductPricePrice>
      </BemFlex>
    </StyledProductPriceBox>
  );
};
