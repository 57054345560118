/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { IPropsUiLibBase, IPropsStyled } from '@bemer/types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const dash = keyframes`
  to {
    stroke-dashoffset: -800;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BgCircle = styled.circle`
  text-transform: uppercase;
  stroke: ${({ theme }: IPropsStyled) => theme.colors?.spinnerBgGray};
`;

const SpinningCircle = styled.circle`
  stroke-dasharray: 120, 280;
  animation: ${dash} 2s infinite linear;
  stroke: ${({ theme }: IPropsStyled) => theme.colors?.brand};
`;

const ChildrenWrapper = styled.div`
  position: absolute;
`;

export type TBemSpinnerSize = 'large' | 'medium' | 'small';

export interface IPropsBemSpinner extends IPropsUiLibBase {
  size?: TBemSpinnerSize;
}

export const BemSpinner = ({
  rawSx,
  children,
  size = 'large',
}: IPropsBemSpinner): JSX.Element => {
  const context = useThemeUI();
  const { theme } = context;
  const pixelSize = {
    large: 130,
    medium: 50,
    small: 20,
  }[size];

  const LOADER_WIDTH = pixelSize;
  const LOADER_HEIGHT = LOADER_WIDTH;
  const STROKE_WIDTH = 4;
  const VIEW_BOX_WIDTH = LOADER_WIDTH + STROKE_WIDTH;
  const VIEW_BOX_HEIGHT = VIEW_BOX_WIDTH;
  const RADIUS = LOADER_WIDTH / 2;
  const CX = VIEW_BOX_WIDTH / 2;
  const CY = VIEW_BOX_HEIGHT / 2;

  return (
    <Wrapper sx={{ ...rawSx }}>
      <svg
        viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`}
        width={LOADER_WIDTH}
        height={LOADER_HEIGHT}
        xmlns="http://www.w3.org/2000/svg"
      >
        <BgCircle
          strokeWidth={STROKE_WIDTH}
          cx={CX}
          cy={CY}
          r={RADIUS}
          fill="transparent"
          theme={theme}
        />
        <SpinningCircle
          strokeWidth={STROKE_WIDTH}
          cx={CX}
          cy={CY}
          r={RADIUS}
          fill="transparent"
          theme={theme}
        />
      </svg>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Wrapper>
  );
};
