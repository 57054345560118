import React from 'react';
import { Flex, FlexProps } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemFlex extends FlexProps, IPropsUiLibBase {}

export const BemFlex = ({
  children,
  rawSx,
  ...rest
}: IPropsBemFlex): JSX.Element => {
  return (
    <Flex sx={rawSx} {...rest}>
      {children}
    </Flex>
  );
};
