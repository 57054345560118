/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { UseFormMethods, ValidationRule } from 'react-hook-form/dist/types';
import { ValidationRules } from 'react-hook-form/dist/types/validator';
import { IPropsUiLibBase, IPropsStyled } from '@bemer/types';
import { BemFormFieldErrorMessage } from './BemFormFieldErrorMessage';

export type TBemInputFieldType = 'text' | 'password' | 'hidden';
export interface IPropsBemInputField extends IPropsUiLibBase {
  type?: TBemInputFieldType;
  id?: string;
  fieldName: string;
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isVisible?: boolean;
  formMethods: UseFormMethods;
  validationRules?: ValidationRules;
  prefix?: ReactNode;
  suffix?: ReactNode;
  isBold?: boolean;
  datalistId?: string;
  autoComplete?: string;
  className?: string;
  testId?: string;
}

interface IPropsAddon extends IPropsStyled {
  hasPrefix?: boolean;
  hasSuffix?: boolean;
}
interface IPropsStyledInput extends IPropsStyled, IPropsAddon {
  isValid: boolean;
  isBold: boolean;
}
interface IPropsStyledLabel extends IPropsStyled, IPropsAddon {}

interface IPropsStyledWrapper {
  isVisible?: boolean;
}

const INPUT_HEIGHT = 56;
const INPUT_WITH_ADDON_PADDING = 50;

const StyledInput = styled.input`
  color: ${({ theme }: IPropsStyled) => theme.colors?.text};
  background-color: ${({ theme }: IPropsStyledInput) => theme.colors?.white};
  font-size: 16px;
  width: 100%;
  height: ${INPUT_HEIGHT}px;
  padding-top: 10px;
  padding-left: ${({ hasPrefix }: IPropsStyledInput) =>
    hasPrefix ? `${INPUT_WITH_ADDON_PADDING}px` : '16px'};
  padding-right: ${({ hasSuffix }: IPropsStyledInput) =>
    hasSuffix ? `${INPUT_WITH_ADDON_PADDING}px` : '16px'};

  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }: IPropsStyledInput) => theme.colors?.coolGray};
  border-bottom-color: ${({ theme, isValid }: IPropsStyledInput) =>
    isValid ? theme.colors?.coolGray : 'transparent'};
  font-weight: ${({ isBold }: IPropsStyledInput) =>
    isBold ? 'bold' : 'normal'};

  &:focus {
    outline: none;
    border-color: ${({ theme }: IPropsStyledInput) => theme.colors?.primary};
  }
  &::placeholder {
    color: ${({ theme }: IPropsStyledInput) => theme.colors?.coolGray};
    opacity: 1;
    transition: all 0.2s;
  }
  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
  &:placeholder-shown:not(:focus) + * {
    font-size: 16px;
    opacity: 1;
    top: 15px;
  }

  /* Disabled styles */
  &:disabled,
  &[aria-disabled='true'] {
    color: ${({ theme }: IPropsStyledInput) => theme.colors?.coolGray};
  }
`;

const Wrapper = styled.div<IPropsStyledWrapper>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: relative;
  margin-bottom: 16px;
`;

const StyledLabel = styled.label<IPropsStyledLabel>`
  position: absolute;
  top: 4px;
  cursor: text;
  font-size: 12px;
  color: ${({ theme }) => theme.colors?.coolGray};
  left: ${({ hasPrefix }) =>
    hasPrefix ? `${INPUT_WITH_ADDON_PADDING}px` : '17.5px'};
  opacity: 1;
  transition: all 0.2s;
`;

const Addon = styled.div<IPropsAddon>`
  align-items: center;
  display: flex;
  height: ${INPUT_HEIGHT}px;
  padding: 0 17px;
  position: absolute;
  right: ${({ hasSuffix }: IPropsAddon) => (hasSuffix ? 0 : 'auto')};
  top: 0;
  color: ${({ theme }) => theme.colors?.coolGray};
`;

export const BemInputField = ({
  type = 'text',
  id,
  fieldName,
  label = '',
  defaultValue,
  formMethods,
  rawSx,
  placeholder,
  validationRules = {},
  T = () => '',
  isDisabled = false,
  isVisible = true,
  prefix = null,
  suffix = null,
  isBold = false,
  datalistId,
  autoComplete = 'on',
  className,
  testId,
}: IPropsBemInputField): JSX.Element | null => {
  const context = useThemeUI();
  const { theme } = context;
  const isOptional = !Object.keys(validationRules).includes('required');

  let maxLength = 999999;
  if (Object.keys(validationRules).includes('maxLength')) {
    const unifiedMaxLengthRule: ValidationRule =
      typeof validationRules.maxLength === 'object'
        ? validationRules.maxLength
        : {
            value: validationRules.maxLength as number,
            message: '',
          };
    maxLength = unifiedMaxLengthRule.value as number;
  }

  const labelText = `${label}${isOptional ? ` ${T('field_is_optional')}` : ''}`;

  const isValid = !Object.keys(formMethods.errors).includes(fieldName);
  const isHidden = type === 'hidden';

  if (isHidden && (label || suffix || prefix)) {
    console.warn('label, suffix, prefix will not be shown for hidden elements');
  }

  return (
    <Wrapper
      sx={{
        ...rawSx,
      }}
      className={className}
      isVisible={isVisible}
    >
      {prefix && !isHidden && (
        <Addon theme={theme} hasPrefix>
          {prefix}
        </Addon>
      )}
      <StyledInput
        id={id || fieldName}
        type={type}
        name={fieldName}
        placeholder={placeholder || label || ' '}
        defaultValue={defaultValue}
        disabled={isDisabled}
        isValid={isValid}
        theme={theme}
        ref={formMethods.register(validationRules)}
        hasPrefix={!!prefix}
        hasSuffix={!!suffix}
        isBold={isBold}
        list={datalistId}
        autoComplete={autoComplete}
        data-testid={testId || `field__${fieldName}`}
        maxLength={maxLength}
      />
      {!isHidden && (
        <StyledLabel
          htmlFor={id || fieldName}
          theme={theme}
          hasPrefix={!!prefix}
        >
          {labelText}
        </StyledLabel>
      )}

      {suffix && !isHidden && (
        <Addon theme={theme} hasSuffix>
          {suffix}
        </Addon>
      )}
      <BemFormFieldErrorMessage
        fieldName={fieldName}
        formMethods={formMethods}
        theme={theme}
      />
    </Wrapper>
  );
};
