import { validatePostCodeForOrderABemerTrial } from '@bemer/middleware';
import { ILocale } from '@bemer/types';
import React, { useState } from 'react';
import { BemSpinner } from '../Spinner';
import { BemInputField, IPropsBemInputField } from './BemInputField';

interface IPropsBemPostCodeField extends IPropsBemInputField {
  locale: ILocale;
  checkBackend?: boolean;
}

const BemPostCodeField = ({
  checkBackend = false,
  validationRules = {},
  ...props
}: IPropsBemPostCodeField) => {
  const { T = () => '', formMethods, locale } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  let backendValdidation = {};
  if (checkBackend) {
    backendValdidation = {
      validate: async (value: string) => {
        setShowSpinner(true);
        const result = await validatePostCodeForOrderABemerTrial(value, locale);

        if (result.valid) {
          formMethods.setValue('federalState', result.federalState);
        }

        setShowSpinner(false);
        return result.valid || T('error__postCode_validation');
      },
    };
  }

  return (
    <BemInputField
      {...props}
      suffix={showSpinner ? <BemSpinner size="small" /> : null}
      validationRules={{ ...validationRules, ...backendValdidation }}
    />
  );
};

export { BemPostCodeField };
