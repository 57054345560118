import React from 'react';
import { Box, BoxProps } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemBox extends BoxProps, IPropsUiLibBase {}

export const BemBox = ({
  children,
  rawSx,
  ...rest
}: IPropsBemBox): JSX.Element => {
  return (
    <Box sx={rawSx} {...rest}>
      {children}
    </Box>
  );
};
