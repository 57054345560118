/** @jsx jsx */
import { IPropsUiLibBase } from '@bemer/types';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';

export interface IPropsBemList extends IPropsUiLibBase {
  className?: string;
  level: number;
}

const olTypeMapping = ['decimal', 'lower-latin', 'lower-roman'];

type TListProps = {
  level?: number;
};

const StyledList = styled.ul<TListProps>`
  padding-left: 30px;
  margin-left: -10px;
  list-style-type: ${({ level = 0 }) =>
    olTypeMapping[level % olTypeMapping.length]};
`;

export const BemUnorderedList = ({
  className,
  rawSx,
  children,
}: IPropsBemList): JSX.Element => (
  <StyledList className={className} sx={rawSx}>
    {children}
  </StyledList>
);

export const BemOrderedList = ({
  className,
  rawSx,
  level,
  children,
}: IPropsBemList): JSX.Element => (
  <StyledList as="ol" level={level - 1} className={className} sx={rawSx}>
    {children}
  </StyledList>
);
