/** @jsx jsx */
import { jsx, Theme } from 'theme-ui';
import styled from '@emotion/styled';

import { UseFormMethods } from 'react-hook-form/dist/types';
import { ErrorMessage } from '@hookform/error-message';
import { IPropsUiLibBase, IPropsStyled } from '@bemer/types';

export interface IPropsBemFormFieldErrorMessage extends IPropsUiLibBase {
  fieldName: string;
  formMethods?: UseFormMethods;
  theme: Theme;
}

const StyledErrorMessage = styled.p`
  color: ${({ theme }: IPropsStyled) => theme.colors?.text};
  background: ${({ theme }: IPropsStyled) => theme.colors?.dangerLightest};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }: IPropsStyled) => theme.colors?.coolGray};
  border-top: none;

  margin: 0;
  padding: 13px 0 16px 24px;
  font-size: 16px;
  font-weight: normal;
`;

export const BemFormFieldErrorMessage = ({
  fieldName,
  formMethods,
  theme,
}: IPropsBemFormFieldErrorMessage): JSX.Element | null => {
  if (!formMethods) {
    return null;
  }
  return (
    <ErrorMessage
      errors={formMethods.errors}
      name={fieldName}
      render={({ message }) => (
        <StyledErrorMessage theme={theme}>{message}</StyledErrorMessage>
      )}
    />
  );
};
