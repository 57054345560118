import React from 'react';
import { IPropsUiLibBase } from '@bemer/types';
import styled from '@emotion/styled';
import { BemBox } from '../Box/BemBox';
import { BemFlex } from '../Flex';
import { BemImage, IPropsSanityImage } from '../Image/BemImage';

export type TImagePosition = 'right' | 'left';
export interface IPropsBemTextWithImage extends IPropsUiLibBase {
  image: IPropsSanityImage;
  imagePosition?: TImagePosition;
}

const Wrapper = styled(BemFlex)`
  position: relative;
  align-items: center;
`;

export const BemTextWithImage = ({
  image,
  children,
  imagePosition = 'left',
  rawSx,
}: IPropsBemTextWithImage): JSX.Element => {
  return (
    <Wrapper
      rawSx={{
        flexDirection: imagePosition === 'right' ? 'row' : 'row-reverse',
        width: '100%',
        ...rawSx,
      }}
    >
      <BemImage image={image} rawSx={{ width: '56.6%' }} />
      <BemBox
        rawSx={{
          width: '29.6%',
          m: imagePosition === 'right' ? '0 80px 0 95px' : '0 95px 0 80px',
        }}
      >
        {children}
      </BemBox>
    </Wrapper>
  );
};
