/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemSection extends IPropsUiLibBase {
  isFullWidth?: boolean;
  isCentered?: boolean;
}

const StyledSection = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0 80px;
  margin-bottom: 130px;
`;

export const BemSection = ({
  isFullWidth,
  isCentered,
  rawSx,
  children,
}: IPropsBemSection): JSX.Element => {
  return (
    <StyledSection
      sx={{
        px: isFullWidth ? 0 : '80px',
        alignItems: isCentered ? 'center' : 'start',
        ...rawSx,
      }}
    >
      {children}
    </StyledSection>
  );
};
