/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactNode } from 'react';
import styled from '@emotion/styled';

import { IPropsUiLibBase, TBlockContentBlocks } from '@bemer/types';
import { BemSection } from '../../components/Section';
import { BemHeadline } from '../../components/Headline';
import { BemBlockContent } from '../../components/BlockContent';
import { BemIcon } from '../../components/Icon';

type TProcessResultIcon = 'times' | 'check';

export interface IPropsBemModuleProcessResult extends IPropsUiLibBase {
  iconName: TProcessResultIcon;
  title: string;
  copy: TBlockContentBlocks;
  button?: ReactNode;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: start;

  padding-top: 100px;
`;

const StyledIconBox = styled.div`
  position: relative;
  min-width: 128px;
  width: 128px;
  height: 128px;
  margin-right: 40px;
  margin-top: 10px;
`;
const StyledTextContentBox = styled.div`
  max-width: 540px;
`;

const StyledButtonBox = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`;

export const BemModuleProcessResult = ({
  iconName,
  title,
  copy,
  button,
  rawSx,
}: IPropsBemModuleProcessResult): JSX.Element => {
  return (
    <BemSection rawSx={rawSx} isCentered>
      <StyledContainer>
        <StyledIconBox>
          <BemIcon
            iconName={iconName}
            rawSx={{
              position: 'absolute',
              top: 40,
              left: 56,
              transform: 'scale(7.9)',
            }}
          />
        </StyledIconBox>
        <StyledTextContentBox>
          <BemHeadline rank="h3">{title}</BemHeadline>
          <BemBlockContent blocks={copy} />
          <StyledButtonBox>{button}</StyledButtonBox>
        </StyledTextContentBox>
      </StyledContainer>
    </BemSection>
  );
};
