/** @jsx jsx */
import { jsx } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';
import styled from '@emotion/styled';
import { getIconComp } from './AvailableIcons';

export interface IPropsBemIcon extends IPropsUiLibBase {
  iconName: string;
  title?: string;
}

const Wrapper = styled.div`
  display: flex;
`;

export const BemIcon = ({
  iconName,
  title,
  rawSx,
}: IPropsBemIcon): JSX.Element | null => {
  if (!iconName) {
    return null;
  }

  return (
    <Wrapper
      sx={{
        ...rawSx,
      }}
      title={title}
    >
      {getIconComp(iconName)}
    </Wrapper>
  );
};
