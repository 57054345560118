/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemOverlay extends IPropsUiLibBase {}

const StyledOverlay = styled.div`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 21, 32, 0.7);
  z-index: 1000;
`;

const StyledChildrenWrapper = styled.div`
  position: relative;
  width: fit-content;
  z-index: 2000;
`;

const Portal = ({ children }: IPropsUiLibBase) => {
  const className = 'root-portal';
  const element = 'div';
  const [container] = useState(document.createElement(element));

  container.classList.add(className);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export const BemOverlay = ({
  rawSx,
  children,
}: IPropsBemOverlay): JSX.Element => {
  return (
    <Portal>
      <StyledOverlay sx={rawSx}>
        <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      </StyledOverlay>
    </Portal>
  );
};
