/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import styled from '@emotion/styled';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemStepper extends IPropsUiLibBase {
  current?: number;
}

const Wrapper = styled.div<IPropsBemStepper>`
  display: flex;
  width: 100%;
`;

export const BemStepper = ({
  rawSx,
  children,
  current = 0,
}: IPropsBemStepper): JSX.Element => {
  const getStatus = (currentStep: number, stepIndex: number): string => {
    const stepNumber = stepIndex + 1;
    if (currentStep === stepNumber) return 'processing';
    if (currentStep > stepNumber) return 'completed';
    return 'waiting';
  };

  return (
    <Wrapper current={current} sx={rawSx}>
      {React.Children.map(children, (child, index) => {
        const status = getStatus(current, index);

        if (React.isValidElement(child)) {
          return React.cloneElement(child, { status, stepNumber: index + 1 });
        }
        return child;
      })}
    </Wrapper>
  );
};
