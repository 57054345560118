import { ILocale } from '@bemer/types';

const autoCorrectTaxIdentificationNumberCH = (
  value: string,
  previousValue: string
) => {
  if (!previousValue || value.length > previousValue.length) {
    if (value.length === 3) {
      return `${value}-`;
    }

    if (value.length === 7 || value.length === 11) {
      return `${value}.`;
    }

    if (value.length === 15) {
      return `${value} `;
    }
  }

  return value;
};

const autoCorrectTaxIdentificationNumberUS = (
  value: string,
  previousValue: string
) => {
  const onlyNumbers = value.replace(/[^\d]/g, ''); // Get all numbers of the string

  // Only append dashes when typing forward.
  if (!previousValue || value.length > previousValue.length) {
    if (onlyNumbers.length === 2) {
      return `${onlyNumbers}-`;
    }
  }

  if (onlyNumbers.length <= 2) {
    return onlyNumbers;
  }

  return `${onlyNumbers.slice(0, 2)}-${onlyNumbers.slice(2, 9)}`;
};

type TRegExpTaxPattern = {
  [key: string]: {
    regExp: RegExp;
    autoCorrect: (value: string, previousValue: string) => string;
  };
};

const regExpMapTaxIdentificationNumberByCountry: TRegExpTaxPattern = {
  // RegExp's need to be double-checked before uncommenting them again

  // 'AT': {regExp: /^(A|AT|ATU)\d{0,8}$/g},
  // 'BE': {regExp: /^(B|BE)\d{0,10}$/g},
  CH: {
    regExp: /^(CHE|CH|C)-?(\d{0,3})?\.?(\d{0,3})?\.?(\d{0,3})?\s?(MWST|MWS|MW|M)?(TVA|TV|T)?(IVA|IV|I)?$/g,
    autoCorrect: autoCorrectTaxIdentificationNumberCH,
  },
  // 'DE': {regExp: /^(D|DE)\d{0,9}$/g},
  // 'DK': {regExp: /^(D|DK)\d{0,8}$/g},
  // 'EE': {regExp: /^(E|EE)\d{0,9}$/g},
  // 'ES': {regExp: /^(ES[A-Z]\d{0,7}[\dA-Z])|(ES[\dA-Z]\d{0,7}[A-Z])$/g},
  // 'FI': {regExp: /^(F|FI)\d{0,8}$/g},
  // 'FR': {regExp: /^(F|FR)[0-9A-Z]{0,2}?\d{0,9}$/g},
  // 'GB': {regExp: /^(GB\d{0,12})|(GB\d{0,9})|(GB[A-Z]{0,2}\d{0,3})$/g},
  // 'IE': {regExp: /^((I|IE)\d{0,7}[A-Z][A-Z])|((I|IE)\d[\dA-Z]\d{0,5}[A-Z])$/g},
  // 'IT': {regExp: /^(I|IT)\d{0,11}$/g},
  // 'LI': {regExp: /.*/g},
  // 'LT': {regExp: /^(L|LT)\d{0,12}$/g},
  // 'LU': {regExp: /^(L|LU)\d{0,8}$/g},
  // 'LV': {regExp: /^(L|LV)\d{0,11}$/g},
  // 'NL': {regExp: /^(N|NL)\d{0,9}B?\d{0,2}$/g},
  // 'NO': {regExp: /^(N|NO){0,1}\d{0,9}(M|MV|MVA)?$/g},
  // 'PL': {regExp: /^(P|PL)\d{0,10}$/g},
  // 'SE': {regExp: /^(S|SE)\d{0,12}$/g},
  US: {
    regExp: /^\d{0,2}-?\d{0,7}$/g,
    autoCorrect: autoCorrectTaxIdentificationNumberUS,
  },
};

const matchTaxIdentificationNumber = (
  regexp: RegExp,
  value: string,
  previousValue: string
) => {
  if (
    (!previousValue || value.length > previousValue.length) &&
    !value.match(regexp)
  ) {
    return previousValue;
  }

  return value;
};

export const normalizeTaxIdentificationNumber = (
  taxId: string,
  locale: ILocale
) => {
  const previousValue = taxId;

  if (!locale || !locale?.countryCode) {
    return taxId;
  }

  const value = taxId.toUpperCase().trim();

  const regExp = regExpMapTaxIdentificationNumberByCountry[locale.countryCode];
  if (!regExp) return value;

  const autoCorrectedValue = (regExp.autoCorrect || ((v) => v))(
    value,
    previousValue
  );

  return matchTaxIdentificationNumber(
    regExpMapTaxIdentificationNumberByCountry[locale.countryCode]?.regExp,
    autoCorrectedValue,
    previousValue
  );
};
