/** @jsx jsx */
import { jsx, useThemeUI, Theme } from 'theme-ui';
import styled from '@emotion/styled';
import React, { ForwardedRef, ReactNode } from 'react';

import {
  IPropsUiLibBase,
  IPropsStyled,
  ILocale,
  TTranslationFunction,
  ICountriesToLanguages,
} from '@bemer/types';

import { ENVIRONMENTS } from '@bemer/middleware';
import { BemLogo } from '../Logo';
import { BemLocaleSelector, ILocaleSelectorData } from '../LocaleSelector';
import { BemBox } from '../Box';
import { BemLink } from '../Link';
import { BemPill } from '../Pill';

export interface IContact {
  contactLabel: string;
  contactPhoneNumber: string;
}

export interface IPropsBemHeader extends IPropsUiLibBase {
  locale: ILocale;
  localeSelectorData: ILocaleSelectorData;
  contact?: IContact;
  env?: string;
}

const StyledTopBar = styled.div`
  display: flex;
  flex-direction: row-reverse; // As long as there is only one item in the TopBar.
  align-items: center;
  background-color: ${({ theme }: IPropsStyled) => theme.colors?.topBarGray};
  height: 40px;
  padding: 0 80px;
  color: ${({ theme }: IPropsStyled) => theme.colors?.black};
`;
interface ITopBar {
  theme: Theme;
  locale: ILocale;
  T: TTranslationFunction;
  countriesToLanguages: ICountriesToLanguages[];
  localeSelectorData: ILocaleSelectorData;
}
const TopBar = ({
  theme,
  locale,
  T,
  countriesToLanguages,
  localeSelectorData,
}: ITopBar): JSX.Element => (
  <StyledTopBar theme={theme}>
    <BemLocaleSelector
      theme={theme}
      locale={locale}
      T={T}
      title={localeSelectorData.title}
      copy={localeSelectorData.copy}
      countriesToLanguages={countriesToLanguages}
      onSwitch={localeSelectorData.onSwitch}
    />
  </StyledTopBar>
);

const StyledNavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }: IPropsStyled) => theme.colors?.paleGray};
  height: 96px;
  padding: 0 80px;
`;

const NavBarItem = styled.p`
  color: ${({ theme }: IPropsStyled) => theme.colors?.navBarGray};
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;

  span {
    color: ${({ theme }: IPropsStyled) => theme.colors?.text};
  }
`;

interface INavBar {
  theme: Theme;
  locale: ILocale;
  children: ReactNode;
  env?: string;
}

const NavBar = ({
  theme,
  children,
  locale,
  env = '',
}: INavBar): JSX.Element => {
  const label = (
    <BemPill
      backgroundColor={theme.colors?.environments[env].backgroundColor}
      color={theme.colors?.environments[env].color}
      rawSx={{
        position: 'absolute',
        bottom: '8px',
        right: '-2px',
      }}
    >
      {env}
    </BemPill>
  );
  return (
    <StyledNavBar theme={theme}>
      <BemBox>
        <BemLink to={`/${locale.id}`} linkColor="text">
          <BemLogo
            label={!env || env === ENVIRONMENTS.PRODUCTION ? null : label}
          />
        </BemLink>
      </BemBox>
      {children}
    </StyledNavBar>
  );
};

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const BemHeader = React.forwardRef(
  (
    {
      rawSx,
      locale,
      T = () => '',
      countriesToLanguages = [],
      localeSelectorData,
      contact,
      env = ENVIRONMENTS.PRODUCTION,
    }: IPropsBemHeader,
    ref: ForwardedRef<HTMLElement>
  ): JSX.Element => {
    const context = useThemeUI();
    const { theme } = context;
    const { contactPhoneNumber, contactLabel } = contact || {};
    return (
      <StyledHeader sx={rawSx} ref={ref}>
        <TopBar
          theme={theme}
          locale={locale}
          T={T}
          countriesToLanguages={countriesToLanguages}
          localeSelectorData={localeSelectorData}
        />
        <NavBar theme={theme} locale={locale} env={env}>
          {contactPhoneNumber && contactLabel && (
            <NavBarItem theme={theme}>
              {contactLabel}: <span>{contactPhoneNumber}</span>
            </NavBarItem>
          )}
        </NavBar>
      </StyledHeader>
    );
  }
);
