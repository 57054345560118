import { IPropsUiLibBase } from '@bemer/types';
import React from 'react';
import { BemText } from '../Text';

export interface IPropsBemListItem extends IPropsUiLibBase {
  className?: string;
}

export const BemListItem = ({
  className,
  rawSx,
  children,
}: IPropsBemListItem): JSX.Element => (
  <BemText
    as="li"
    className={className}
    rawSx={{
      marginLeft: 0,
      marginBottom: '16px',
      paddingLeft: '20px',
      ...rawSx,
    }}
  >
    {children}
  </BemText>
);
