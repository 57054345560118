import React from 'react';
import { Grid, GridProps } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';

export interface IPropsBemGrid extends GridProps, IPropsUiLibBase {}

export const BemGrid = ({
  children,
  width,
  columns,
  gap,
  rawSx,
}: IPropsBemGrid): JSX.Element => {
  return (
    <Grid sx={rawSx} width={width} columns={columns} gap={gap}>
      {children}
    </Grid>
  );
};
