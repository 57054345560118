/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import React from 'react';

import { UseFormMethods } from 'react-hook-form/dist/types';
import { ValidationRules } from 'react-hook-form/dist/types/validator';
import { IPropsUiLibBase, IPropsStyled, ISelectOption } from '@bemer/types';
import { BemFormFieldErrorMessage } from './BemFormFieldErrorMessage';

export interface IPropsBemSelectField extends IPropsUiLibBase {
  id?: string;
  fieldName: string;
  label?: string;
  value?: string;
  options: ISelectOption[];
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  formMethods?: UseFormMethods;
  validationRules?: ValidationRules;
  testId?: string;
}

interface IPropsStyledSelect extends IPropsStyled {
  isValid: boolean;
}

const Wrapper = styled.div`
  display: block;
  position: relative;
  margin-bottom: 16px;
`;

const StyledSelect = styled.select`
  display: block;

  color: ${({ theme }: IPropsStyledSelect) => theme.colors?.text};
  background-color: ${({ theme }: IPropsStyledSelect) => theme.colors?.white};

  font-size: 16px;
  width: 100%;
  height: 56px;
  padding: 10px 16px 0;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }: IPropsStyledSelect) => theme.colors?.coolGray};
  border-bottom-color: ${({ theme, isValid }: IPropsStyledSelect) =>
    isValid ? theme.colors?.coolGray : 'transparent'};

  /* Own arrow icons */
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23100F17%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 20px top 50%;
  background-size: 0.5em auto;
  /* Hide arrow icon in IE browsers */
  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }: IPropsStyledSelect) => theme.colors?.primary};
  }

  /* Disabled styles */
  &:disabled,
  &[aria-disabled='true'] {
    color: ${({ theme }: IPropsStyledSelect) => theme.colors?.coolGray};
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  }

  &::placeholder {
    color: ${({ theme }: IPropsStyledSelect) => theme.colors?.coolGray};
    opacity: 1;
    transition: all 0.2s;
  }
  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
  &:placeholder-shown:not(:focus) + * {
    font-size: 16px;
    opacity: 1;
    top: 15px;
  }
`;

const StyledOption = styled.option`
  padding: 0;
  margin: 0;
  font-weight: normal;
`;

const StyledLabel = styled.label`
  position: absolute;
  left: 17.5px;
  top: 4px;
  cursor: text;
  font-size: 12px;
  color: ${({ theme }: IPropsStyled) => theme.colors?.coolGray};
  opacity: 1;
  transition: all 0.2s;
`;

export const BemSelectField = ({
  id,
  fieldName,
  label = '',
  value,
  formMethods,
  rawSx,
  placeholder,
  options,
  validationRules = {},
  T = () => '',

  isDisabled = false,
  onChange = () => undefined,
  testId,
}: IPropsBemSelectField): JSX.Element | null => {
  const context = useThemeUI();
  const { theme } = context;
  const isOptional = !Object.keys(validationRules).includes('required');
  const labelText = `${label}${isOptional ? ` ${T('field_is_optional')}` : ''}`;

  const isValid =
    !formMethods || !Object.keys(formMethods.errors).includes(fieldName);

  return (
    <Wrapper
      sx={{
        ...rawSx,
      }}
    >
      <StyledSelect
        id={id || fieldName}
        name={fieldName}
        placeholder={placeholder || labelText || ' '}
        defaultValue={value}
        disabled={isDisabled}
        onChange={onChange}
        isValid={isValid}
        theme={theme}
        ref={formMethods && formMethods.register(validationRules)}
        data-testid={testId || `field__${fieldName}`}
      >
        {options.map((option: ISelectOption) => (
          <StyledOption key={option.id || option.value} value={option.value}>
            {option.label}
          </StyledOption>
        ))}
      </StyledSelect>
      <StyledLabel theme={theme} htmlFor={id || fieldName}>
        {labelText}
      </StyledLabel>

      <BemFormFieldErrorMessage
        fieldName={fieldName}
        formMethods={formMethods}
        theme={theme}
      />
    </Wrapper>
  );
};
