import axios from 'axios';
import {
  IFormError,
  ILocale,
  IOrderABemerTrialPayload,
  IOrderABemerTrialSuccessResponse,
  TTranslationFunction,
} from '@bemer/types';
import { normalizeTaxIdentificationNumber } from '../utils/normalizers/taxId';
import { API_ENDPOINTS } from './endpoints';

interface IValidateTaxIDProps {
  valid: boolean;
  taxId: string;
}

interface IValidatePostCodeProps {
  valid: boolean;
  federalState: string;
}

export const validateEmailForOrderABemerTrial = async (
  email: string
): Promise<boolean> => {
  const url = `${
    API_ENDPOINTS.BEMER_TESTEN
  }/check_email?email=${encodeURIComponent(email)}`;

  const name = 'validateEmailForOrderABemerTrial';
  console.log(`[${name}] payload:`, encodeURIComponent(email));
  console.log(`[${name}] url:`, url);
  let response;

  try {
    response = await axios.get(url);
    console.log(`[${name}] response:`, response);

    // TODO: general error handling for request should be more abstract
    if (!response) {
      return Promise.reject(new Error('No response received.'));
    }

    if (!response.data) {
      return Promise.reject(
        new Error(
          `The response has no data: ${JSON.stringify(response, null, 2)}`
        )
      );
    }
    return response.data.valid;
  } catch (error) {
    console.log(
      `[${name}] error:`,
      'status code:',
      error.response?.status,
      'data',
      error.response?.data
    );
    return false;
  }
};
export const validatePostCodeForOrderABemerTrial = async (
  postCode: string,
  locale: ILocale
): Promise<IValidatePostCodeProps> => {
  const url = `${API_ENDPOINTS.BEMER_TESTEN}/check_postcode?country=${
    locale.countryCode
  }&postcode=${encodeURIComponent(postCode)}`;

  const name = 'validatePostCodeForOrderABemerTrial';
  console.log(`[${name}] payload:`, encodeURIComponent(postCode));
  console.log(`[${name}] url:`, url);
  let response;

  try {
    response = await axios.get(url);
    console.log(`[${name}] response:`, response);

    // TODO: general error handling for request should be more abstract
    if (!response) {
      return Promise.reject(new Error('No response received.'));
    }

    if (!response.data) {
      return Promise.reject(
        new Error(
          `The response has no data: ${JSON.stringify(response, null, 2)}`
        )
      );
    }

    return {
      valid: response.data.valid,
      federalState: response.data.federalState,
    };
  } catch (error) {
    console.log(
      `[${name}] error:`,
      'status code:',
      error.response?.status,
      'data',
      error.response?.data
    );
    return { valid: false, federalState: '' };
  }
};

export const validateTaxIdForOrderABemerTrial = async (
  taxId: string,
  locale: ILocale
): Promise<IValidateTaxIDProps> => {
  const normalizedTaxId = normalizeTaxIdentificationNumber(taxId, locale);
  if (!normalizedTaxId) {
    return { valid: false, taxId };
  }

  const url = `${API_ENDPOINTS.TAXID_VALIDATION}/taxId/?country=${locale.countryCode}&id=${normalizedTaxId}`;

  console.log('[validateTaxIdForOrderABemerTrial] payload:', normalizedTaxId);
  console.log('[validateTaxIdForOrderABemerTrial] url:', url);

  const response = await axios.get(url);

  return { valid: response.data.valid, taxId: normalizedTaxId };
};

export const postOrderABemerTrialFormData = async (
  payload: IOrderABemerTrialPayload,
  locale: ILocale,
  T: TTranslationFunction
): Promise<IOrderABemerTrialSuccessResponse | IFormError> => {
  const url = `${API_ENDPOINTS.BEMER_TESTEN}/${locale.id}/signup`;

  console.log('[postTestABemerFormData] payload:', payload);
  console.log('[postTestABemerFormData] url:', url);

  let response;
  try {
    response = await axios.post(url, payload);
    console.log('[postTestABemerFormData] response:', response);

    if (!response) {
      return Promise.reject(new Error('No response received.'));
    }

    if (!response.data) {
      return Promise.reject(
        new Error(
          `The response has no data: ${JSON.stringify(response, null, 2)}`
        )
      );
    }

    if (response.data.status.toLowerCase() !== 'success') {
      return Promise.reject(
        new Error(
          `The response status was not successful: ${response.data.errorMessage}`
        )
      );
    }

    if (
      response.data.paymentType === 'N4CHECKOUT' &&
      !response.data.checkoutUrl
    ) {
      return Promise.reject(
        new Error(
          'The response paymentType is "N4CHECKOUT" but no checkoutUrl was found!'
        )
      );
    }

    return {
      status: response.data.status,
      paymentType: response.data.paymentType || 'PREPAYMENT',
      checkoutUrl: response.data.checkoutUrl || '',
    };
  } catch (error) {
    const buildFormError = (
      message: string,
      headline = T('error__errorBox__generalHeadline')
    ): IFormError => ({
      headline,
      message,
    });
    if (error.response) {
      // client received an error response (5xx, 4xx)
      console.log(
        '[postTestABemerFormData] error:',
        'status code:',
        error.response.status,
        'data',
        error.response.data
      );

      if (error.response.data && error.response.data.validationErrors) {
        // Todo buildFormError
        return Promise.reject<IFormError>(
          buildFormError('TODO: Validation errors')
        );
      }
      if (error.response.data && error.response.data.errorMessage) {
        // Todo buildFormError
        return Promise.reject<IFormError>(
          buildFormError(error.response.data.errorMessage)
        );
      }
      return Promise.reject<IFormError>(buildFormError('Error'));
    }
    if (error.request) {
      // client never received a response, or request never left
      console.log('[postTestABemerFormData] error:', error.request);
      // Todo buildFormError
      return Promise.reject<IFormError>(buildFormError('Error'));
    }
    // anything else
    console.log('[postTestABemerFormData] error:', error);
    // Todo buildFormError
    return Promise.reject<IFormError>(buildFormError('Error'));
  }
};
