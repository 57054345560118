import { IError, ILocale, IPartnerFinderData } from '@bemer/types';
import { API_ENDPOINTS } from './endpoints';

type TPartnerPersistenceStoreType = 'sessionStorage' | 'localStorage' | 'redux';

interface IFetchPartnerByHandleData {
  data: {
    publicPartnerByHandle: IPartnerFinderData;
  };
}

interface IFetchPartnerByNameData {
  data: {
    publicPartnerByName: IPartnerFinderData[];
  };
}

const STORAGE_KEY_PARTNER_FINDER_DATA = 'partnerFinderData';
const STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE =
  'isPartnerGivenViaUrlHandle';

export const fetchPartnerSuggestions = (query: string, partnerLevel = '35') => {
  const url = `${API_ENDPOINTS.PARTNER_FINDER_BY_NAME}`;

  const graphQlQuery = `{
    publicPartnerByName(query: "${query}", level: "${partnerLevel}") {
      finderData {
        countryCode
        partnerFinderName
        partnerFinderPhone
        partnerFinderAddress
        partnerFinderCompany
      }
      partnerData {
        customerNo
        handle
        levelName
        level
        name
      }
    }
   }`;

  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: graphQlQuery }),
  })
    .then((res) => res.json())
    .then(
      (parsedResponse: IFetchPartnerByNameData) =>
        parsedResponse?.data?.publicPartnerByName
    );
};

const fetchPublicPartnerByHandle = (url: string, query: string) => {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  })
    .then((res) => res.json())
    .then((parsedResponse: IFetchPartnerByHandleData) => {
      if (!parsedResponse) {
        throw new Error('[E001]: No partner found');
      }
      if (!parsedResponse.data) {
        throw new Error('[E002]: No data in response found');
      }
      if (!parsedResponse.data.publicPartnerByHandle) {
        throw new Error('[E003]: No publicPartnerByHandle in response found');
      }
      return parsedResponse.data.publicPartnerByHandle;
    });
};

export const fetchPartnerByHandle = async (
  handle: string,
  // level?: number,
  locale?: ILocale
): Promise<IPartnerFinderData> => {
  const url = `${API_ENDPOINTS.PARTNER_FINDER}/selectBox`;

  // const levelParam = level ? `, level: "${level}"` : '';
  const localeParam = locale ? `, locale: "${locale.id}"` : '';

  console.log('[PARTNER SERVICE: fetchPartnerByHandle] handle:', handle);
  console.log('[PARTNER SERVICE: fetchPartnerByHandle] url:', url);

  const query = `
  {
    publicPartnerByHandle(handle: "${handle}"${localeParam}) {
      finderData {
        countryCode
        latitude
        longitude
        partnerFinderAddress
        partnerFinderCompany
        partnerFinderName
        partnerFinderPhone
      }

      partnerData {
        customerNo
        handle
        levelName
        level
        name
      }
    }
  }`;
  return fetchPublicPartnerByHandle(url, query);
};

export const fetchPartnerByCustomerNo = async (
  customerNo: string,
  // level?: number,
  locale?: ILocale
): Promise<IPartnerFinderData> => {
  const url = `${API_ENDPOINTS.PARTNER_FINDER}/selectBox`;

  // const levelParam = level ? `, level: "${level}"` : '';
  const localeParam = locale ? `, locale: "${locale.id}"` : '';

  console.log(
    '[PARTNER SERVICE: fetchPartnerByCustomerNo] customerNo:',
    customerNo
  );
  console.log('[PARTNER SERVICE: fetchPartnerByCustomerNo] url:', url);
  console.log(
    '[PARTNER SERVICE: fetchPartnerByCustomerNo] localeParam:',
    localeParam
  );

  const query = `
  {
    publicPartnerByHandle(customerNo: "${customerNo}"${localeParam}) {
      finderData {
        countryCode
        latitude
        longitude
        partnerFinderAddress
        partnerFinderCompany
        partnerFinderName
        partnerFinderPhone
      }
      partnerData {
        customerNo
        handle
        levelName
        level
        name
      }
    }
  }`;
  return fetchPublicPartnerByHandle(url, query);
};

export const putPartnerFinderDataIntoStore = (
  partnerFinderData: IPartnerFinderData,
  storeType: TPartnerPersistenceStoreType = 'sessionStorage',
  isPartnerGivenViaUrlHandle = false
) => {
  switch (storeType) {
    case 'sessionStorage': {
      window.sessionStorage.setItem(
        STORAGE_KEY_PARTNER_FINDER_DATA,
        JSON.stringify(partnerFinderData)
      );
      if (
        !(
          window.sessionStorage.getItem(
            STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE
          ) === 'true'
        )
      ) {
        window.sessionStorage.setItem(
          STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE,
          JSON.stringify(isPartnerGivenViaUrlHandle)
        );
      }
      break;
    }
    case 'localStorage': {
      window.localStorage.setItem(
        STORAGE_KEY_PARTNER_FINDER_DATA,
        JSON.stringify(partnerFinderData)
      );
      if (
        !(
          window.localStorage.getItem(
            STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE
          ) === 'true'
        )
      ) {
        window.localStorage.setItem(
          STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE,
          JSON.stringify(isPartnerGivenViaUrlHandle)
        );
      }
      break;
    }
    case 'redux': {
      // Todo
      break;
    }
    default: {
      // Nothing to do
    }
  }
  console.log(
    `[PARTNER SERVICE] Setting "${JSON.stringify(
      partnerFinderData
    )}" into ${storeType}.`
  );
};

export const removePartnerFinderDataIntoStore = (
  storeType: TPartnerPersistenceStoreType = 'sessionStorage'
) => {
  switch (storeType) {
    case 'sessionStorage': {
      window.sessionStorage.removeItem(STORAGE_KEY_PARTNER_FINDER_DATA);
      break;
    }
    case 'localStorage': {
      window.localStorage.removeItem(STORAGE_KEY_PARTNER_FINDER_DATA);
      break;
    }
    case 'redux': {
      // Todo
      break;
    }
    default: {
      // Nothing to do
    }
  }
  console.log(
    `[PARTNER SERVICE] Removing partnerFinderData from ${storeType}.`
  );
};

export const checkForPartnerHandleAndPutIntoStore = async (
  level = 0,
  restrictedCountries = ["US", "CA"],
  storeType: TPartnerPersistenceStoreType = 'sessionStorage'
): Promise<IPartnerFinderData | IError> => {
  const reject = (errorMessage: string) => {
    console.warn(errorMessage, '\n\nNo partner will be set to the store.');
    return Promise.reject(new Error(errorMessage));
  };

  if (typeof window === 'undefined') {
    return reject('Not in the browser.');
  }

  const params = new URLSearchParams(window.location.search);
  if (!params.has('partner')) {
    return reject('No partner handle in URL.');
  }
  const partnerHandle = params.get('partner');
  if (!partnerHandle) {
    return reject('No partner handle in URL.');
  }
  let partnerFinderData: IPartnerFinderData;
  try {
    partnerFinderData = await fetchPartnerByHandle(partnerHandle);

    const partnerCountry = partnerFinderData.finderData.countryCode;

    // Exclude partners from restricted countries
    if (restrictedCountries.includes(partnerCountry)) {
      return reject(
        `No partner was found for partner handle "${partnerHandle}" and level "${level}".`
      );
    }

    if (parseInt(partnerFinderData.partnerData.level, 10) < level) {
      return reject(
        `No partner was found for partner handle "${partnerHandle}" and level "${level}".`
      );
    }
  } catch (error) {
    console.error(error);
    return reject(
      `No partner was found for partner handle "${partnerHandle}" and level "${level}".`
    );
  }
  putPartnerFinderDataIntoStore(partnerFinderData, storeType, true);
  return partnerFinderData;
};

export const getPartnerFinderDataFromStore = (
  storeType: TPartnerPersistenceStoreType = 'sessionStorage'
): IPartnerFinderData | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  let partnerFinderData;
  switch (storeType) {
    case 'sessionStorage': {
      partnerFinderData = window.sessionStorage.getItem(
        STORAGE_KEY_PARTNER_FINDER_DATA
      );
      break;
    }
    case 'localStorage': {
      partnerFinderData = window.localStorage.getItem(
        STORAGE_KEY_PARTNER_FINDER_DATA
      );
      break;
    }
    case 'redux': {
      // Todo
      break;
    }
    default: {
      // Nothing to do
    }
  }
  console.log(
    `[PARTNER SERVICE] Getting "${partnerFinderData}" from ${storeType}.`
  );
  return partnerFinderData ? JSON.parse(partnerFinderData) : undefined;
};

export const getIsPartnerGivenViaUrlHandle = (
  storeType: TPartnerPersistenceStoreType = 'sessionStorage'
): IPartnerFinderData | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  let isPartnerGivenViaUrlHandle;
  switch (storeType) {
    case 'sessionStorage': {
      isPartnerGivenViaUrlHandle = window.sessionStorage.getItem(
        STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE
      );
      break;
    }
    case 'localStorage': {
      isPartnerGivenViaUrlHandle = window.localStorage.getItem(
        STORAGE_KEY_IS_PARTNER_GIVEN_VIA_URL_HANDLE
      );
      break;
    }
    case 'redux': {
      // Todo
      break;
    }
    default: {
      // Nothing to do
    }
  }
  console.log(
    `[PARTNER SERVICE] Getting "${isPartnerGivenViaUrlHandle}" from ${storeType}.`
  );
  return isPartnerGivenViaUrlHandle
    ? JSON.parse(isPartnerGivenViaUrlHandle)
    : undefined;
};
