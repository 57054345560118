/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import React from 'react';
import { IPropsUiLibBase } from '@bemer/types';

export type TButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'link';
export type TButtonType = 'submit' | 'reset' | 'button';

export interface IPropsBemButton extends IPropsUiLibBase {
  /**
   * Callback to fire on click.
   */
  onClick?: (event: React.MouseEvent) => void;
  /**
   * Deactivates the button and displays it in a disabled state.
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Hierarchy of the button.
   * @default "primary"
   */
  variant?: TButtonVariant;
  /**
   * Maps to the type attribute of the HTML <button> element.
   * @default "button"
   */
  type?: TButtonType;
  testId: string;
}

const StyledButton = styled.button`
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  padding: 15px 55px;
  border-width: 2px;
  border-style: solid;
  letter-spacing: 0.89px;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const BemButton = ({
  children,
  onClick,
  isDisabled,
  variant = 'primary',
  type = 'button',
  rawSx,
  testId,
}: IPropsBemButton): JSX.Element => (
  <StyledButton
    sx={{
      variant: `styles.buttons.${variant}`,
      ...rawSx,
    }}
    type={type}
    onClick={onClick}
    disabled={isDisabled}
    data-testid={testId}
  >
    {children}
  </StyledButton>
);
