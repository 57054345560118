/** @jsx jsx */
import { jsx } from 'theme-ui';

import { IPropsUiLibBase } from '@bemer/types';
import { BemSection } from '../../components/Section';
import { BemHeadline } from '../../components/Headline';
import { BemFlex } from '../../components/Flex';

export interface IPropsBemModuleProductDetailsBox extends IPropsUiLibBase {
  title: string;
  theme?: string;
}
export const BemModuleProductDetailsBox = ({
  title,
  rawSx,
  children,
}: IPropsBemModuleProductDetailsBox): JSX.Element => {
  return (
    <BemSection
      rawSx={{
        bg: 'paleGray',
        p: '108px 0 8px',
        ...rawSx,
      }}
      isFullWidth
    >
      <BemFlex
        rawSx={{
          textAlign: 'center',
          justifyContent: 'center',
          width: '100%',
          mb: '90px',
        }}
      >
        <BemHeadline rank="h1" isCentered rawSx={{ maxWidth: '550px' }}>
          {title}
        </BemHeadline>
      </BemFlex>
      {children}
    </BemSection>
  );
};
