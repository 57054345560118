/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { IPropsUiLibBase, IPropsStyled } from '@bemer/types';

export type TStatus = 'completed' | 'processing' | 'waiting';
interface IStepWrapper extends IPropsStyled {
  status?: TStatus;
}

const StepWrapper = styled.div<IStepWrapper>`
  flex-basis: 0;
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  padding: 16px 0;
  font-size: 20px;
  border-bottom: 1px solid transparent;
  border-color: ${({ status, theme }: IStepWrapper) => {
    if (status !== 'waiting') return theme.colors?.black;
    return theme.colors?.paleGrayTwo;
  }};
  color: ${({ status, theme }: IStepWrapper) => {
    if (status !== 'waiting') return theme.colors?.black;
    return theme.colors?.coolGray;
  }};
  font-weight: ${({ status }: IStepWrapper) => {
    if (status === 'processing') return 'bold';
    return 'normal';
  }};
`;

const Prefix = styled.div`
  margin-right: 10px;
`;

export interface IPropsBemStep extends IPropsUiLibBase {
  title: string;
  icon?: ReactNode;
  status?: TStatus;
  stepNumber?: number;
}

export const BemStep = ({
  title,
  icon,
  status,
  stepNumber,
}: IPropsBemStep): JSX.Element => {
  const context = useThemeUI();
  const { theme } = context;

  return (
    <StepWrapper theme={theme} status={status}>
      <Prefix>{icon || stepNumber}</Prefix>
      {title}
    </StepWrapper>
  );
};
