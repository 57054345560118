/** @jsx jsx */
import {
  IPropsUiLibBase,
  TBlockContentBlocks,
  TContentBlock,
  TContentMarks,
} from '@bemer/types';
import BlockContent from '@sanity/block-content-to-react';
import { jsx, SxStyleProp } from 'theme-ui';
import { BemLink } from '../Link';
import { BemListItem, BemOrderedList, BemUnorderedList } from '../List';
import { BemText } from '../Text';

export interface IBemBlockContent extends IPropsUiLibBase {
  rawBemTextSx?: SxStyleProp;
  blocks: TBlockContentBlocks;
}

const BlockRenderer = (props: TContentBlock, rawBemTextSx: SxStyleProp) => {
  switch (props.node.style) {
    case 'h1':
      return <h1>{props.children}</h1>;
    case 'h2':
      return <h2>{props.children}</h2>;
    case 'h3':
      return <h3>{props.children}</h3>;
    case 'h4':
      return <h4>{props.children}</h4>;
    case 'h5':
      return <h5>{props.children}</h5>;
    case 'h6':
      return <h6>{props.children}</h6>;
    case 'a':
      return <h6>{props.children}</h6>;

    case 'blockquote':
      return <blockquote>{props.children}</blockquote>;
    default:
      return <BemText rawSx={rawBemTextSx}>{props.children}</BemText>;
  }
};

const LinkRenderer = ({ children, mark: { href } }: TContentMarks) => (
  <BemLink to={href}>{children}</BemLink>
);

const ListRenderer = ({ children, type, level }: TContentMarks) => {
  switch (type) {
    case 'number':
      return <BemOrderedList level={level}> {children}</BemOrderedList>;
    default:
      return <BemUnorderedList level={level}>{children}</BemUnorderedList>;
  }
};

const ListItemRenderer = (props: TContentBlock) => <BemListItem {...props} />;

const InternalLinkRenderer = ({
  children,
  mark: { reference, target = '' },
}: TContentMarks) => (
  <BemLink to={reference.slug.current} target={target}>
    {children}
  </BemLink>
);

export const BemBlockContent = ({
  blocks,
  rawSx,
  rawBemTextSx = {},
}: IBemBlockContent): JSX.Element => (
  <BlockContent
    blocks={blocks}
    serializers={{
      marks: {
        link: (props) => LinkRenderer(props),
        internalLink: (props) => InternalLinkRenderer(props),
      },
      types: { block: (props) => BlockRenderer(props, rawBemTextSx) },
      listItem: ListItemRenderer,
      list: ListRenderer,
    }}
    sx={rawSx}
  />
);
