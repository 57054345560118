/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsStyled, IPropsUiLibBase } from '@bemer/types';
import { Link } from 'gatsby';

export type TLinkColor = 'primary' | 'text';

export interface IPropsBemLink extends IPropsUiLibBase {
  to: string;
  linkColor?: TLinkColor;
  target?: '_blank' | '' | undefined;
}

interface IPropsStyledLink extends IPropsStyled {
  color: TLinkColor;
}

const StyledLink = styled.a<IPropsStyledLink>`
  text-decoration: none;
  color: ${({ theme, color }) => (theme.colors || {})[color]};
`;

const StyledGatsbyLink = styled(Link)<IPropsStyledLink>`
  text-decoration: none;
  color: ${({ theme, color }) => (theme.colors || {})[color]};
`;

export const BemLink = ({
  to,
  linkColor = 'primary',
  rawSx,
  children,
  target,
}: IPropsBemLink): JSX.Element => {
  const context = useThemeUI();
  const { theme } = context;
  // External links use a tag
  if (
    to?.includes('://') ||
    to?.includes('mailto:') ||
    to?.includes('tel:') ||
    target
  ) {
    const linkTarget = to.includes('://') ? '_blank' : '';
    return (
      <StyledLink
        sx={rawSx}
        theme={theme}
        color={linkColor}
        href={to}
        rel="noreferrer"
        target={linkTarget || target}
      >
        {children}
      </StyledLink>
    );
  }

  // Internal links use the Gatsby Link
  return (
    <StyledGatsbyLink sx={rawSx} theme={theme} color={linkColor} to={to}>
      {children}
    </StyledGatsbyLink>
  );
};
