import React from 'react';
import { buildLocale } from '@bemer/middleware';
import {
  ICountriesToLanguages,
  ILocale,
  TTranslationFunction,
  TCurrency,
} from '@bemer/types';

interface IDefaultValue {
  locale: ILocale;
  T: TTranslationFunction;
  countriesToLanguages: ICountriesToLanguages[];
  currency: TCurrency;
}

const defaultValue: IDefaultValue = {
  locale: buildLocale('de_DE', 'EUR'),
  currency: 'EUR',
  T: () => '',
  countriesToLanguages: [],
};

const TranslationContext = React.createContext(defaultValue);

export default TranslationContext;
