import { graphql, StaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  IGraphqlJsonDataVersionStats,
  IGraphqlSiteSettings,
} from '@bemer/types';
import theme from '@bemer/ui-library/src/gatsby-plugin-theme-ui/index';
import { ENVIRONMENTS } from '@bemer/middleware';
import TranslationContext from '../../context/translationContext';

interface ISanitySiteSetting {
  node: IGraphqlSiteSettings;
}

interface IJsonDataVersionStats {
  node: IGraphqlJsonDataVersionStats;
}

const AppHelmet = (): JSX.Element => {
  const { locale } = useContext(TranslationContext);
  const canRenderGoogleScripts =
    process.env.GATSBY_GOOGLE_ANALYTICS_ID &&
    process.env.GATSBY_GOOGLE_TAG_MANAGER_ID &&
    process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
  const googleScripts = canRenderGoogleScripts
    ? [
        <script
          key="google_tag_manager"
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}`}
        />,
        <script key="google_analytics">{`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}');
      `}</script>,
        <script
          key="google_optimize"
          src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GATSBY_GOOGLE_OPTIMIZE_ID}`}
        />,
      ]
    : null;

  return (
    <StaticQuery
      query={graphql`
        query metaQuery {
          versionStatsData: allDataJson {
            edges {
              node {
                buildDate
                version
                versionDomainAndNumber
              }
            }
          }

          dataset: sitePlugin(name: { eq: "gatsby-source-sanity" }) {
            id
            pluginOptions {
              dataset
            }
          }

          allSanitySiteSettings {
            edges {
              node {
                language {
                  localeIdentifier
                }

                meta {
                  title
                }
              }
            }
          }
        }
      `}
      render={({
        allSanitySiteSettings: { edges: settings },
        dataset: {
          pluginOptions: { dataset },
        },
        versionStatsData,
      }) => {
        const localeSettings = settings.filter(
          ({ node: setting }: ISanitySiteSetting) =>
            setting.language.localeIdentifier === locale.id
        )?.[0]?.node;

        const buildDate =
          versionStatsData.edges.filter(
            (edge: IJsonDataVersionStats) => !!edge.node.buildDate
          )[0]?.node.buildDate || 'no build date found';

        const { meta } = localeSettings;
        const htmlAttributes = {
          lang: locale.languageCode,
        };

        let faviconDataUri = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28'%3E%3Cpath fill='%23fff' d='M27.738 17.936a7.222 7.222 0 00-1.996-4.962 6.91 6.91 0 001.86-4.73c0-3.836-3.121-6.958-6.957-6.958H2.235A2.241 2.241 0 000 3.53v19.71a2.242 2.242 0 002.234 2.245A2.242 2.242 0 004.47 23.24V5.49l16.808-.002c1.517.118 1.956 1.356 1.984 2.655.02 1.429-.462 2.677-2.08 2.713H11.48c-1.274 0-2.235.903-2.235 2.1 0 1.2.96 2.106 2.235 2.106h9.743c1.583.019 2.048 1.611 2.048 2.96 0 1.358-.352 2.74-1.891 3.005l-11.903.012c-1.274 0-2.235.903-2.235 2.1 0 1.198.96 2.102 2.235 2.102l11 .004.069-.004a7.226 7.226 0 007.186-7.096l.006-.208z'/%3E%3C/svg%3E`;
        switch (dataset) {
          case ENVIRONMENTS.DEVELOPMENT:
          case ENVIRONMENTS.STAGE:
            faviconDataUri = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64px' height='64px' viewBox='0 0 64 64'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect fill='${encodeURIComponent(
              (theme?.colors?.environments[dataset]
                .backgroundColor as string) || ''
            )}' x='0' y='0' width='64' height='64'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E`;
            break;
          default:
            break;
        }

        return (
          <Helmet htmlAttributes={htmlAttributes}>
            <meta name="buildDate" content={buildDate} />
            <meta name="google" content="notranslate" />
            <meta httpEquiv="Content-Language" content={locale.languageCode} />
            {googleScripts}
            <link href={faviconDataUri} rel="icon" type="image/x-icon" />
            <title>{meta?.title}</title>;
          </Helmet>
        );
      }}
    />
  );
};

export default AppHelmet;
