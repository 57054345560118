/** @jsx jsx */
import { jsx, useThemeUI, SxStyleProp } from 'theme-ui';
import { useState, useRef, ReactNode } from 'react';
import { IPropsUiLibBase, IPropsStyled } from '@bemer/types';
import styled from '@emotion/styled';

export type TPlacement =
  | 'top'
  | 'topLeft'
  | 'topRight'
  | 'bottom'
  | 'bottomLeft'
  | 'bottomRight';

export interface IPropsBemPopover extends IPropsUiLibBase {
  placement?: TPlacement;
  content?: ReactNode | string;
}

interface IPropsOverlay extends IPropsStyled {
  placement?: TPlacement;
  sx?: SxStyleProp;
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const Overlay = styled.div<IPropsOverlay>`
  z-index: 1;
  position: absolute;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.colors?.background};
  color: ${({ theme }) => theme.colors?.text};
  transform: ${({ placement = 'top' }) => {
    if (placement === 'bottom') return 'translate(-50%, 100%)';
    if (placement === 'top') return 'translate(-50%, -100%)';
    if (placement.startsWith('top')) return 'translate(-0, -100%)';
    if (placement.startsWith('bottom')) return 'translate(0, 100%)';
    return 'translate(-50%, -100%)';
  }};
  top: ${({ placement = 'top' }) =>
    placement.startsWith('top') ? '-10px' : 'auto'};
  bottom: ${({ placement = 'top' }) =>
    placement.startsWith('bottom') ? '-10px' : 'auto'};
  left: ${({ placement = 'top' }) => {
    if (placement === 'top' || placement === 'bottom') return '50%';
    return placement.endsWith('Left') ? 0 : 'auto';
  }};
  right: ${({ placement = 'top' }) =>
    placement.endsWith('Right') ? 0 : 'auto'};
`;

const Arrow = styled.div<IPropsOverlay>`
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background: 0 0;
  border-style: solid;
  border-width: 4px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: ${({ placement = 'top' }) =>
    placement.startsWith('bottom')
      ? 'translateX(-50%) rotate(-135deg)'
      : 'translateX(-50%) rotate(45deg)'};
  top: ${({ placement = 'top' }) =>
    placement.startsWith('bottom') ? '-4px' : 'auto'};
  bottom: ${({ placement = 'top' }) =>
    placement.startsWith('bottom') ? 'auto' : '-4px'};
  left: ${({ placement = 'top' }) => {
    if (placement.endsWith('Left')) return '20px';
    if (placement.endsWith('Right')) return 'auto';
    return '50%';
  }};
  right: ${({ placement = 'top' }) => {
    if (placement.endsWith('Right')) return '20px';
    if (placement.endsWith('Left')) return 'auto';
    return '50%';
  }};
`;

export const BemPopover = ({
  children,
  rawSx,
  placement,
  content,
}: IPropsBemPopover): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const targetRef = useRef<HTMLInputElement>(null);
  const context = useThemeUI();
  const { theme } = context;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Wrapper
      ref={targetRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isHovered && (
        <Overlay theme={theme} sx={rawSx} placement={placement}>
          {content}
          <Arrow theme={theme} placement={placement} />
        </Overlay>
      )}
    </Wrapper>
  );
};
