/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase, TBlockContentBlocks } from '@bemer/types';
import { BemSection } from '../../components/Section';
import { BemHeadline } from '../../components/Headline';
import { BemBlockContent } from '../../components/BlockContent';
import { BemGrid } from '../../components/Grid';

export interface IProductFact {
  title: string;
  copy: TBlockContentBlocks;
}

const StyledProductFact = styled.div`
  margin-bottom: 60px;
`;

export const ProductFact = ({ title, copy }: IProductFact): JSX.Element => {
  return (
    <StyledProductFact>
      <BemHeadline rank="h1" rawSx={{ mb: '6px' }}>
        {title}
      </BemHeadline>
      <BemBlockContent blocks={copy} />
    </StyledProductFact>
  );
};

const StyledProductFactsColumn = styled.div`
  margin-top: -5px;
`;

export interface IPropsBemModuleProductFacts extends IPropsUiLibBase {
  title: string;
  productFacts: IProductFact[];
}
export const BemModuleProductFacts = ({
  title,
  productFacts,
  rawSx,
}: IPropsBemModuleProductFacts): JSX.Element => {
  return (
    <BemSection rawSx={rawSx}>
      <BemGrid
        rawSx={{
          px: ['0', null, null, '95px'],
        }}
        gap={[0, null, null, '170px']}
        columns={[1, null, 2, '400px 300px']}
      >
        <BemHeadline rank="h2" rawSx={{ mb: '30px' }}>
          {title}
        </BemHeadline>
        <StyledProductFactsColumn>
          {productFacts.map(({ title: factTitle, copy }: IProductFact) => (
            <ProductFact key={factTitle} title={factTitle} copy={copy} />
          ))}
        </StyledProductFactsColumn>
      </BemGrid>
    </BemSection>
  );
};
