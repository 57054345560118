import { ILocale, ISelectOption } from '@bemer/types';

interface IStates {
  [key: string]: string;
}

const DMY = 'DMY';
const YMD = 'YMD';
const MDY = 'MDY';

type IDateFormats = typeof DMY | typeof YMD | typeof MDY;

interface IDateFormatsByCountry {
  [key: string]: IDateFormats;
}

interface ICheckTaxIdByCountry {
  [key: string]: boolean;
}

/* eslint-disable @typescript-eslint/naming-convention */

const de_AT: IStates = {
  AT1: 'Burgenland',
  AT2: 'Kärnten',
  AT3: 'Niederösterreich',
  AT4: 'Oberösterreich',
  AT5: 'Salzburg',
  AT6: 'Steiermark',
  AT7: 'Tirol',
  AT8: 'Vorarlberg',
  AT9: 'Wien',
};
// const de_BE = {}; // MISSING TRANSLATION
// const fr_BE = {}; // MISSING TRANSLATION

const nl_BE: IStates = {
  BRU: 'Brussels Hoofdstedelijk Gewest',
  VLG: 'Vlaams Gewest',
  WAL: 'Waals Gewest',
  VAN: 'Antwerpen',
  VLI: 'Limburg',
  VOV: 'Oost-Vlaanderen',
  VBR: 'Vlaams-Brabant',
  VWV: 'West-Vlaanderen',
  WBR: 'Waals-Brabant',
  WHT: 'Henegouwen',
  WLG: 'Luik',
  WLX: 'Luxemburg',
  WNA: 'Namen',
};
const en_CA: IStates = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  YT: 'Yukon',
};
const fr_CA: IStates = {
  AB: 'Alberta',
  BC: 'Colombie-Britannique',
  MB: 'Manitoba',
  NB: 'Nouveau-Brunswick',
  NL: 'Terre-Neuve-et-Labrador',
  NS: 'Nouvelle-Écosse',
  ON: 'Ontario',
  PE: 'Île-du-Prince-Édouard',
  QC: 'Québec',
  SK: 'Saskatchewan',
  NT: 'Territoires du Nord-Ouest',
  NU: 'Nunavut',
  YT: 'Yukon',
};
const de_CH: IStates = {
  AG: 'Aargau',
  AR: 'Appenzell Ausserrhoden',
  AI: 'Appenzell Innerrhoden',
  BL: 'Basel-Landschaft',
  BS: 'Basel-Stadt',
  BE: 'Bern',
  FR: 'Freiburg',
  GE: 'Genf',
  GL: 'Glarus',
  GR: 'Graubünden',
  JU: 'Jura',
  LU: 'Luzern',
  NE: 'Neuenburg',
  NW: 'Nidwalden',
  OW: 'Obwalden',
  SH: 'Schaffhausen',
  SZ: 'Schwyz',
  SO: 'Solothurn',
  SG: 'St. Gallen',
  TI: 'Tessin',
  TG: 'Thurgau',
  UR: 'Uri',
  VD: 'Waadt',
  VS: 'Wallis',
  ZG: 'Zug',
  ZH: 'Zürich',
};
// const fr_CH:IStates = {}; // MISSING TRANSLATION
// const it_CH:IStates = {}; // MISSING TRANSLATION
const cs_CZ: IStates = {
  '31': 'Jihočeský kraj',
  '64': 'Jihomoravský kraj',
  '41': 'Karlovarský kraj',
  '52': 'Královéhradecký kraj',
  '51': 'Liberecký kraj',
  '80': 'Moravskoslezský kraj',
  '71': 'Olomoucký kraj',
  '53': 'Pardubický kraj',
  '32': 'Plzeňský kraj',
  '10': 'Hlavní město Praha',
  '20': 'Středočeský kraj',
  '42': 'Ústecký kraj',
  '63': 'Kraj Vysočina',
  '72': 'Zlínský kraj',
  '201': 'Benešov',
  '202': 'Beroun',
  '203': 'Kladno',
  '204': 'Kolín',
  '205': 'Kutná Hora',
  '206': 'Mělník',
  '207': 'Mladá Boleslav',
  '208': 'Nymburk',
  '209': 'Praha-východ',
  '20A': 'Praha-západ',
  '20B': 'Příbram',
  '20C': 'Rakovník',
  '311': 'České Budějovice',
  '312': 'Český Krumlov',
  '313': 'Jindřichův Hradec',
  '314': 'Písek',
  '315': 'Prachatice',
  '316': 'Strakonice',
  '317': 'Tábor',
  '321': 'Domažlice',
  '322': 'Klatovy',
  '323': 'Plzeň-jih',
  '324': 'Plzeň-město ',
  '325': 'Plzeň-sever',
  '326': 'Rokycany',
  '327': 'Tachov',
  '411': 'Cheb',
  '412': 'Karlovy Vary',
  '413': 'Sokolov',
  '421': 'Děčín',
  '422': 'Chomutov',
  '423': 'Litoměřice',
  '424': 'Louny',
  '425': 'Most',
  '426': 'Teplice',
  '427': 'Ústí nad Labem',
  '511': 'Česká Lípa',
  '512': 'Jablonec nad Nisou',
  '513': 'Liberec',
  '514': 'Semily',
  '521': 'Hradec Králové',
  '522': 'Jičín',
  '523': 'Náchod',
  '524': 'Rychnov nad Kněžnou',
  '525': 'Trutnov',
  '531': 'Chrudim',
  '532': 'Pardubice',
  '533': 'Svitavy',
  '534': 'Ústí nad Orlicí',
  '631': 'Havlíčkův Brod',
  '632': 'Jihlava',
  '633': 'Pelhřimov',
  '634': 'Třebíč',
  '635': 'Žďár nad Sázavou',
  '641': 'Blansko',
  '642': 'Brno-město',
  '643': 'Brno-venkov',
  '644': 'Břeclav',
  '645': 'Hodonín',
  '646': 'Vyškov',
  '647': 'Znojmo',
  '711': 'Jeseník',
  '712': 'Olomouc',
  '713': 'Prostějov',
  '714': 'Přerov',
  '715': 'Šumperk',
  '721': 'Kroměříž',
  '722': 'Uherské Hradiště',
  '723': 'Vsetín',
  '724': 'Zlín',
  '801': 'Bruntál',
  '802': 'Frýdek-Místek',
  '803': 'Karviná',
  '804': 'Nový Jičín',
  '805': 'Opava',
  '806': 'Ostrava-město',
};
const de_DE: IStates = {
  BW: 'Baden-Württemberg',
  BY: 'Bayern',
  BE: 'Berlin',
  BB: 'Brandenburg',
  HB: 'Bremen',
  HH: 'Hamburg',
  HE: 'Hessen',
  MV: 'Mecklenburg-Vorpommern',
  NI: 'Niedersachsen',
  NW: 'Nordrhein-Westfalen',
  RP: 'Rheinland-Pfalz',
  SL: 'Saarland',
  SN: 'Sachsen',
  ST: 'Sachsen-Anhalt',
  SH: 'Schleswig-Holstein',
  TH: 'Thüringen',
};
const da_DK: IStates = {
  '81': 'Nordjylland ',
  '82': 'Midtjylland',
  '83': 'Syddanmark ',
  '84': 'Hovedstaden ',
  '85': 'Sjælland',
};
const es_ES: IStates = {
  AN: 'Andalucía',
  AR: 'Aragón',
  AS: 'Principado de Asturias',
  IB: 'Illes Balears',
  PV: 'País Vasco',
  EX: 'Extremadura',
  GA: 'Galicia',
  CN: 'Canarias',
  CB: 'Cantabria',
  CL: 'Castilla y León',
  CM: 'Castilla-La Mancha',
  CT: 'Cataluña',
  RI: 'La Rioja',
  MD: 'Comunidad de Madrid',
  MC: 'Región de Murcia',
  NC: 'Comunidad Foral de Navarra',
  VC: 'Comunidad Valenciana',
  C: 'La Coruña',
  VI: 'Álava',
  AB: 'Albacete',
  A: 'Alicante',
  AL: 'Almería',
  O: 'Asturias',
  AV: 'Ávila',
  BA: 'Badajoz',
  PM: 'Islas Baleares',
  B: 'Barcelona',
  BU: 'Burgos',
  CC: 'Cáceres',
  CA: 'Cádiz',
  CS: 'Castellón',
  CR: 'Ciudad Real',
  CO: 'Córdoba',
  CU: 'Cuenca',
  GI: 'Gerona',
  GR: 'Granada',
  GU: 'Guadalajara',
  SS: 'Guipúzcoa',
  H: 'Huelva',
  HU: 'Huesca',
  J: 'Jaén',
  S: 'Cantabria',
  LO: 'La Rioja',
  GC: 'Las Palmas',
  LE: 'León',
  L: 'Lérida',
  LU: 'Lugo',
  M: 'Madrid',
  MA: 'Málaga',
  MU: 'Murcia',
  NA: 'Navarra',
  OR: 'Orense',
  P: 'Palencia',
  PO: 'Pontevedra',
  SA: 'Salamanca',
  TF: 'Santa Cruz de Tenerife',
  Z: 'Saragossa',
  SG: 'Segovia',
  SE: 'Sevilla',
  SO: 'Soria',
  T: 'Tarragona',
  TE: 'Teruel ',
  TO: 'Toledo',
  V: 'Valencia',
  VA: 'Valladolid',
  BI: 'Vizcaya',
  ZA: 'Zamora',
  CE: 'Ceuta',
  ML: 'Melilla',
};
const fi_FI: IStates = {
  '01': 'Ahvenanmaan maakunta',
  '05': 'Kainuu',
  '06': 'Kanta-Häme',
  '09': 'Kymenlaakso',
  '10': 'Lappi',
  '08': 'Keski-Suomi',
  '07': 'Keski-Pohjanmaa',
  '13': 'Pohjois-Karjala',
  '14': 'Pohjois-Pohjanmaa',
  '15': 'Pohjois-Savo',
  '16': 'Päijät-Häme',
  '12': 'Pohjanmaa',
  '11': 'Pirkanmaa',
  '17': 'Satakunta',
  '02': 'Etelä-Karjala',
  '03': 'Etelä-Pohjanmaa',
  '04': 'Etelä-Savo',
  '18': 'Uusimaa',
  '19': 'Varsinais-Suomi',
};
const fr_FR: IStates = {
  ARA: 'Auvergne-Rhône-Alpes',
  BFC: 'Bourgogne-Franche-Comté',
  BRE: 'Bretagne',
  CVL: 'Centre-Val de Loire',
  GES: 'Grand Est',
  HDF: 'Hauts-de-France',
  COR: 'Korsika (Corse)',
  IDF: 'Île-de-France',
  NOR: 'Normandie',
  NAQ: 'Nouvelle-Aquitaine',
  OCC: 'Okzitanien',
  PDL: 'Pays de la Loire',
  PAC: 'Provence-Alpes-Côte d’Azur',
  '01': 'Ain',
  '02': 'Aisne',
  '03': 'Allier',
  '04': 'Alpes-de-Haute-Provence',
  '05': 'Hautes-Alpes',
  '06': 'Alpes-Maritimes',
  '07': 'Ardèche',
  '08': 'Ardennes',
  '09': 'Ariège',
  '10': 'Aube',
  '11': 'Aude',
  '12': 'Aveyron',
  '13': 'Bouches-du-Rhône',
  '14': 'Calvados',
  '15': 'Cantal',
  '16': 'Charente',
  '17': 'Charente-Maritime',
  '18': 'Cher',
  '19': 'Corrèze',
  '2A': 'Corse-du-Sud',
  '2B': 'Haute-Corse',
  '21': 'Côte-d’Or',
  '22': 'Côtes-d’Armor',
  '23': 'Creuse',
  '24': 'Dordogne',
  '25': 'Doubs',
  '26': 'Drôme',
  '27': 'Eure',
  '28': 'Eure-et-Loir',
  '29': 'Finistère',
  '30': 'Gard',
  '31': 'Haute-Garonne',
  '32': 'Gers',
  '33': 'Gironde',
  '34': 'Hérault',
  '35': 'Ille-et-Vilaine',
  '36': 'Indre',
  '37': 'Indre-et-Loire',
  '38': 'Isère',
  '39': 'Jura',
  '40': 'Landes',
  '41': 'Loir-et-Cher',
  '42': 'Loire',
  '43': 'Haute-Loire',
  '44': 'Loire-Atlantique',
  '45': 'Loiret',
  '46': 'Lot',
  '47': 'Lot-et-Garonne',
  '48': 'Lozère',
  '49': 'Maine-et-Loire',
  '50': 'Manche',
  '51': 'Marne',
  '52': 'Haute-Marne',
  '53': 'Mayenne',
  '54': 'Meurthe-et-Moselle',
  '55': 'Meuse',
  '56': 'Morbihan',
  '57': 'Moselle',
  '58': 'Nièvre',
  '59': 'Nord',
  '60': 'Oise',
  '61': 'Orne',
  '62': 'Pas-de-Calais',
  '63': 'Puy-de-Dôme',
  '64': 'Pyrénées-Atlantiques',
  '65': 'Hautes-Pyrénées',
  '66': 'Pyrénées-Orientales',
  '67': 'Bas-Rhin',
  '68': 'Haut-Rhin',
  '69 (D)': 'Rhône',
  '69 (M)': 'Métropole de Lyon',
  '70': 'Haute-Saône',
  '71': 'Saône-et-Loire',
  '72': 'Sarthe',
  '73': 'Savoie',
  '74': 'Haute-Savoie',
  '75': 'Paris',
  '76': 'Seine-Maritime',
  '77': 'Seine-et-Marne',
  '78': 'Yvelines',
  '79': 'Deux-Sèvres',
  '80': 'Somme',
  '81': 'Tarn',
  '82': 'Tarn-et-Garonne',
  '83': 'Var',
  '84': 'Vaucluse',
  '85': 'Vendée',
  '86': 'Vienne',
  '87': 'Haute-Vienne',
  '88': 'Vosges',
  '89': 'Yonne',
  '90': 'Territoire de Belfort',
  '91': 'Essonne',
  '92': 'Hauts-de-Seine',
  '93': 'Seine-Saint-Denis',
  '94': 'Val-de-Marne',
  '95': 'Val-d’Oise',
  GP: 'Guadeloupe',
  YT: 'Mayotte',
  RE: 'Réunion',
  GF: 'Guyane française',
  PF: 'Polynésie française',
  TF: 'Terres australes françaises sans Terre Adélie',
  MQ: 'Martinique',
  NC: 'Nouvelle-Calédonie',
  BL: 'Saint-Barthélemy',
  MF: 'Saint-Martin',
  PM: 'Saint-Pierre-et-Miquelon',
  WF: 'Wallis-et-Futuna',
  CP: 'Clipperton',
};
const en_GB: IStates = {
  BKM: 'Buckinghamshire',
  CAM: 'Cambridgeshire',
  CMA: 'Cumbria',
  DBY: 'Derbyshire',
  DEV: 'Devon',
  DOR: 'Dorset',
  ESX: 'East Sussex',
  ESS: 'Essex',
  GLS: 'Gloucestershire',
  HAM: 'Hampshire',
  HRT: 'Hertfordshire',
  KEN: 'Kent',
  LAN: 'Lancashire',
  LEC: 'Leicestershire',
  LIN: 'Lincolnshire',
  NFK: 'Norfolk',
  NYK: 'North Yorkshire',
  NTH: 'Northamptonshire',
  NTT: 'Nottinghamshire',
  OXF: 'Oxfordshire',
  SOM: 'Somerset',
  STS: 'Staffordshire',
  SFK: 'Suffolk',
  SRY: 'Surrey',
  WAR: 'Warwickshire',
  WSX: 'West Sussex',
  WOR: 'Worcestershire',
  BDG: 'Barking and Dagenham',
  BNE: 'Barnet',
  BEX: 'Bexley',
  BEN: 'Brent',
  BRY: 'Bromley',
  CMD: 'Camden',
  CRY: 'Croydon',
  EAL: 'Ealing',
  ENF: 'Enfield',
  GRE: 'Greenwich',
  HCK: 'Hackney',
  HMF: 'Hammersmith and Fulham',
  HRY: 'Haringey',
  HRW: 'Harrow',
  HAV: 'Havering',
  HIL: 'Hillingdon',
  HNS: 'Hounslow',
  ISL: 'Islington',
  KEC: 'Kensington and Chelsea',
  KTT: 'Kingston upon Thames',
  LBH: 'Lambeth',
  LEW: 'Lewisham',
  MRT: 'Merton',
  NWM: 'Newham',
  RDB: 'Redbridge',
  RIC: 'Richmond upon Thames',
  SWK: 'Southwark',
  STN: 'Sutton',
  TWH: 'Tower Hamlets',
  WFT: 'Waltham Forest',
  WND: 'Wandsworth',
  WSM: 'Westminster',
  BNS: 'Barnsley (South Yorkshire)',
  BIR: 'Birmingham (West Midlands)',
  BOL: 'Bolton (Greater Manchester)',
  BRD: 'Bradford (West Yorkshire)',
  BUR: 'Bury (Greater Manchester)',
  CLD: 'Calderdale (West Yorkshire)',
  COV: 'Coventry (West Midlands)',
  DNC: 'Doncaster (South Yorkshire)',
  DUD: 'Dudley (West Midlands)',
  GAT: 'Gateshead (Tyne & Wear)',
  KIR: 'Kirklees (West Yorkshire)',
  KWL: 'Knowsley (Merseyside)',
  LDS: 'Leeds (West Yorkshire)',
  LIV: 'Liverpool (Merseyside)',
  MAN: 'Manchester (Greater Manchester)',
  NET: 'Newcastle upon Tyne (Tyne & Wear)',
  NTY: 'North Tyneside (Tyne & Wear)',
  OLD: 'Oldham (Greater Manchester)',
  RCH: 'Rochdale (Greater Manchester)',
  ROT: 'Rotherham (South Yorkshire)',
  SHN: 'St Helens (Merseyside)',
  SLF: 'Salford (Greater Manchester)',
  SAW: 'Sandwell (West Midlands)',
  SFT: 'Sefton (Merseyside)',
  SHF: 'Sheffield (South Yorkshire)',
  SOL: 'Solihull (West Midlands)',
  STY: 'South Tyneside (Tyne & Wear)',
  SKP: 'Stockport (Greater Manchester)',
  SND: 'Sunderland (Tyne & Wear)',
  TAM: 'Tameside (Greater Manchester)',
  TRF: 'Trafford (Greater Manchester)',
  WKF: 'Wakefield (West Yorkshire)',
  WLL: 'Walsall (West Midlands)',
  WGN: 'Wigan (Greater Manchester)',
  WRL: 'Wirral (Merseyside)',
  WLV: 'Wolverhampton (West Midlands)',
  BAS: 'Bath and North East Somerset',
  BBD: 'Blackburn with Darwen',
  BDF: 'Bedford',
  BPL: 'Blackpool',
  BMH: 'Bournemouth',
  BRC: 'Bracknell Forest',
  BNH: 'Brighton and Hove',
  BST: 'Bristol, City of',
  CBF: 'Central Bedfordshire',
  CHE: 'Cheshire East',
  CHW: 'Cheshire West and Chester',
  CON: 'Cornwall',
  DAL: 'Darlington',
  DER: 'Derby',
  DUR: 'Durham',
  ERY: 'East Riding of Yorkshire',
  HAL: 'Halton',
  HPL: 'Hartlepool',
  HEF: 'Herefordshire, County of',
  IOW: 'Isle of Wight',
  IOS: 'Isles of Scilly',
  KHL: 'Kingston upon Hull, City of',
  LCE: 'Leicester',
  LUT: 'Luton',
  MDW: 'Medway',
  MDB: 'Middlesbrough',
  MIK: 'Milton Keynes',
  NEL: 'North East Lincolnshire',
  NLN: 'North Lincolnshire',
  NSM: 'North Somerset',
  NBL: 'Northumberland',
  NGM: 'Nottingham',
  PTE: 'Peterborough',
  PLY: 'Plymouth',
  POL: 'Poole',
  POR: 'Portsmouth',
  RDG: 'Reading',
  RCC: 'Redcar and Cleveland',
  RUT: 'Rutland',
  SHR: 'Shropshire',
  SLG: 'Slough',
  SGC: 'South Gloucestershire',
  STH: 'Southampton',
  SOS: 'Southend-on-Sea',
  STT: 'Stockton-on-Tees',
  STE: 'Stoke-on-Trent',
  SWD: 'Swindon',
  TFW: 'Telford and Wrekin',
  THR: 'Thurrock',
  TOB: 'Torbay',
  WRT: 'Warrington',
  WBK: 'West Berkshire',
  WIL: 'Wiltshire',
  WNM: 'Windsor and Maidenhead',
  WOK: 'Wokingham',
  YOR: 'York',
  ANN: 'Antrim and Newtownabbey',
  AND: 'Ards and North Down',
  ABC: 'Armagh City, Banbridge and Craigavon',
  BFS: 'Belfast',
  CCG: 'Causeway Coast and Glens',
  DRS: 'Derry City and Strabane',
  FMO: 'Fermanagh and Omagh',
  LBC: 'Lisburn and Castlereagh',
  MEA: 'Mid and East Antrim',
  MUL: 'Mid Ulster',
  NMD: 'Newry, Mourne and Down',
  ABE: 'Aberdeen',
  ABD: 'Aberdeenshire',
  ANS: 'Angus',
  AGB: 'Argyll and Bute',
  CLK: 'Clackmannanshire',
  DGY: 'Dumfries and Galloway',
  DND: 'Dundee',
  EAY: 'East Ayrshire',
  EDU: 'East Dunbartonshire',
  ELN: 'East Lothian',
  ERW: 'East Renfrewshire',
  EDH: 'Edinburgh',
  ELS: 'Eilean Siar (Äußere Hebriden)',
  FAL: 'Falkirk',
  FIF: 'Fife',
  GLG: 'Glasgow',
  HLD: 'Highland',
  IVC: 'Inverclyde',
  MLN: 'Midlothian',
  MRY: 'Moray',
  NAY: 'North Ayrshire',
  NLK: 'North Lanarkshire',
  ORK: 'Orkney Islands',
  PKN: 'Perth and Kinross',
  RFW: 'Renfrewshire',
  SCB: 'Scottish Borders, The',
  ZET: 'Shetland Islands',
  SAY: 'South Ayrshire',
  SLK: 'South Lanarkshire',
  STG: 'Stirling',
  WDU: 'West Dunbartonshire',
  WLN: 'West Lothian',
  BGW: 'Blaenau Gwent',
  BGE: 'Bridgend',
  CAY: 'Caerphilly',
  CRF: 'Cardiff',
  CMN: 'Carmarthenshire',
  CGN: 'Ceredigion',
  CWY: 'Conwy',
  DEN: 'Denbighshire',
  FLN: 'Flintshire',
  GWN: 'Gwynedd',
  AGY: 'Isle of Anglesey',
  MTY: 'Merthyr Tydfil',
  MON: 'Monmouthshire',
  NTL: 'Neath Port Talbot',
  NWP: 'Newport',
  PEM: 'Pembrokeshire',
  POW: 'Powys',
  RCT: 'Rhondda Cynon Taf',
  SWA: 'Swansea',
  TOF: 'Torfaen',
  VGL: 'Vale of Glamorgan',
  WRX: 'Wrexham',
};
const hu_HU: IStates = {
  BK: 'Bács-Kiskun',
  BA: 'Baranya',
  BE: 'Békés',
  BC: 'Békéscsaba',
  BZ: 'Borsod-Abaúj-Zemplén',
  BU: 'Budapest',
  CS: 'Csongrád-Csanád',
  DE: 'Debrecen',
  DU: 'Dunaújváros',
  EG: 'Eger',
  ER: 'Érd',
  FE: 'Fejér',
  GY: 'Győr',
  GS: 'Győr-Moson-Sopron',
  HB: 'Hajdú-Bihar',
  HE: 'Heves',
  HV: 'Hódmezővásárhely',
  JN: 'Jász-Nagykun-Szolnok',
  KV: 'Kaposvár',
  KM: 'Kecskemét',
  KE: 'Komárom-Esztergom',
  MI: 'Miskolc',
  NK: 'Nagykanizsa',
  NO: 'Nógrád',
  NY: 'Nyíregyháza',
  PS: 'Pécs',
  PE: 'Pest',
  ST: 'Salgótarján',
  SO: 'Somogy',
  SN: 'Sopron',
  SZ: 'Szabolcs-Szatmár-Bereg',
  SD: 'Szeged',
  SF: 'Székesfehérvár',
  SS: 'Szekszárd',
  SK: 'Szolnok',
  SH: 'Szombathely',
  TB: 'Tatabánya',
  TO: 'Tolna',
  VA: 'Vas',
  VM: 'Veszprém',
  VE: 'Veszprém (Komitat)',
  ZA: 'Zala',
  ZE: 'Zalaegerszeg',
};
const en_IE: IStates = {
  C: 'Connacht',
  L: 'Leinster',
  M: 'Munster',
  U: 'Ulster',
  CW: 'Carlow',
  CN: 'Cavan',
  CE: 'Clare',
  CO: 'Cork',
  DL: 'Donegal',
  D: 'Dublin',
  G: 'Galway',
  KY: 'Kerry',
  KE: 'Kildare',
  KK: 'Kilkenny',
  LS: 'Laois',
  LM: 'Leitrim',
  LK: 'Limerick',
  LD: 'Longford',
  LH: 'Louth',
  MO: 'Mayo',
  MH: 'Meath',
  MN: 'Monaghan',
  OY: 'Offaly',
  RN: 'Roscommon',
  SO: 'Sligo',
  TA: 'Tipperary',
  WD: 'Waterford',
  WH: 'Westmeath',
  WX: 'Wexford',
  WW: 'Wicklow',
};
const de_LI: IStates = {
  '01': 'Balzers',
  '02': 'Eschen',
  '03': 'Gamprin',
  '04': 'Mauren',
  '05': 'Planken',
  '06': 'Ruggell',
  '07': 'Schaan',
  '08': 'Schellenberg',
  '09': 'Triesen',
  '10': 'Triesenberg',
  '11': 'Vaduz',
};
const lt_LT: IStates = {
  AL: 'Alytaus apskritis',
  KU: 'Kauno apskritis',
  KL: 'Klaipėdos apskritis',
  MR: 'Marijampolės apskritis',
  PN: 'Panevėžio apskritis',
  SA: 'Šiaulių apskritis',
  TA: 'Tauragės apskritis',
  TE: 'Telšių apskritis',
  UT: 'Utenos apskritis',
  VL: 'Vilniaus apskritis',
  '01': 'Akmenė',
  '02': 'Alytus miestas',
  '03': 'Alytus',
  '04': 'Anykščiai',
  '05': 'Birštono',
  '06': 'Biržai',
  '07': 'Druskininkai',
  '08': 'Elektrėnai',
  '09': 'Ignalina',
  '10': 'Jonava',
  '11': 'Joniškis',
  '12': 'Jurbarkas',
  '13': 'Kaišiadorys',
  '14': 'Kalvarijos',
  '15': 'Kauno miestas',
  '16': 'Kaunas',
  '17': 'Kazlų Rūdos',
  '18': 'Kėdainiai',
  '19': 'Kelmė',
  '20': 'Klaipėdos miestas',
  '21': 'Klaipėda',
  '22': 'Kretinga',
  '23': 'Kupiškis',
  '24': 'Lazdijai',
  '25': 'Marijampolė',
  '26': 'Mažeikiai',
  '27': 'Molėtai',
  '28': 'Neringa',
  '29': 'Pagėgiai',
  '30': 'Pakruojis',
  '31': 'Palangos miestas',
  '32': 'Panevėžio miestas',
  '33': 'Panevėžys',
  '34': 'Pasvalys',
  '35': 'Plungė',
  '36': 'Prienai',
  '37': 'Radviliškis',
  '38': 'Raseiniai',
  '39': 'Rietavo',
  '40': 'Rokiškis',
  '41': 'Šakiai',
  '42': 'Šalčininkai',
  '43': 'Šiaulių miestas',
  '44': 'Šiauliai',
  '45': 'Šilalė',
  '46': 'Šilutė',
  '47': 'Širvintos',
  '48': 'Skuodas',
  '49': 'Švenčionys',
  '50': 'Tauragė',
  '51': 'Telšiai',
  '52': 'Trakai',
  '53': 'Ukmergė',
  '54': 'Utena',
  '55': 'Varėna',
  '56': 'Vilkaviškis',
  '57': 'Vilniaus miestas',
  '58': 'Vilnius',
  '59': 'Visaginas',
  '60': 'Zarasai',
};
const de_LU: IStates = {
  CA: 'Capellen',
  CL: 'Clerf',
  DI: 'Diekirch',
  EC: 'Echternach',
  ES: 'Esch an der Alzette',
  GR: 'Grevenmacher',
  LU: 'Luxemburg',
  ME: 'Mersch',
  RD: 'Redingen',
  RM: 'Remich',
  VD: 'Vianden',
  WI: 'Wiltz',
};
// const fr_LU:IStates = {}; // MISSING TRANSLATION
const nl_NL: IStates = {
  DR: 'Drenthe',
  FL: 'Flevoland',
  FR: 'Fryslân',
  GE: 'Gelderland',
  GR: 'Groningen',
  LI: 'Limburg',
  NB: 'Noord-Brabant',
  NH: 'Noord-Holland',
  OV: 'Overijssel',
  UT: 'Utrecht',
  ZE: 'Zeeland',
  ZH: 'Zuid-Holland',
  AW: 'Aruba',
  CW: 'Curaçao',
  SX: 'Sint Maarten',
  BQ1: 'Bonaire',
  BQ2: 'Saba',
  BQ3: 'Sint Eustatius',
};
const no_NO: IStates = {
  '01': 'Østfold',
  '02': 'Akershus',
  '03': 'Oslo',
  '04': 'Hedmark',
  '05': 'Oppland',
  '06': 'Buskerud',
  '07': 'Vestfold',
  '08': 'Telemark',
  '09': 'Aust-Agder',
  '10': 'Vest-Agder',
  '11': 'Rogaland',
  '12': 'Hordaland',
  '14': 'Sogn og Fjordane',
  '15': 'Møre og Romsdal',
  '18': 'Nordland',
  '19': 'Troms',
  '20': 'Finnmark',
  '21': 'Svalbard',
  '22': 'Jan Mayen',
  '50': 'Trøndelag',
};
const pl_PL: IStates = {
  '28': 'Warmińsko-Mazurskie',
  '30': 'Wielkopolskie',
  '26': 'Świętokrzyskie',
  '18': 'Podkarpackie',
  '12': 'Małopolskie',
  '04': 'Kujawsko-Pomorskie',
  '08': 'Lubuskie',
  '06': 'Lubelskie',
  '10': 'Łódzkie',
  '14': 'Mazowieckie',
  '02': 'Dolnośląskie',
  '16': 'Opolskie',
  '20': 'Podlaskie',
  '22': 'Pomorskie',
  '24': 'Śląskie',
  '32': 'Zachodniopomorskie',
};
const sv_SE: IStates = {
  K: 'Blekinge län',
  W: 'Dalarnas län',
  I: 'Gotlands län',
  X: 'Gävleborgs län',
  N: 'Hallands län',
  Z: 'Jämtlands län',
  F: 'Jönköpings län',
  H: 'Kalmar län',
  G: 'Kronobergs län',
  BD: 'Norrbottens län',
  M: 'Skåne län',
  AB: 'Stockholms län',
  D: 'Södermanlands län',
  C: 'Uppsala län',
  S: 'Värmlands län',
  AC: 'Västerbottens län',
  Y: 'Västernorrlands län',
  U: 'Västmanlands län',
  O: 'Västra Götalands län',
  T: 'Örebro län',
  E: 'Östergötlands län',
};
const sk_SK: IStates = {
  BC: 'Banskobystrický kraj',
  BL: 'Bratislavský kraj',
  KI: 'Košický kraj',
  NI: 'Nitriansky kraj',
  PV: 'Prešovský kraj',
  TC: 'Trenčiansky kraj',
  TA: 'Trnavský kraj',
  ZI: 'Žilinský kraj',
};
const en_US: IStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
  AS: 'American Samoa',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  UM: 'United States Minor Outlying Islands',
  VI: 'U.S. Virgin Islands',
};
/* eslint-enable @typescript-eslint/naming-convention */

export const federalStates = {
  de_AT,
  de_BE: nl_BE,
  fr_BE: nl_BE,
  nl_BE,
  en_CA,
  fr_CA,
  de_CH,
  fr_CH: de_CH,
  it_CH: de_CH,
  cs_CZ,
  de_DE,
  da_DK,
  es_ES,
  fi_FI,
  fr_FR,
  en_GB,
  hu_HU,
  en_IE,
  de_LI,
  lt_LT,
  de_LU,
  fr_LU: de_LU,
  nl_NL,
  no_NO,
  pl_PL,
  sv_SE,
  sk_SK,
  en_US,
};

// list based on https://en.wikipedia.org/wiki/Date_format_by_country
export const dateFormatByCountry: IDateFormatsByCountry = {
  AT: DMY,
  BE: DMY,
  CA: YMD,
  CH: DMY,
  CZ: DMY,
  DE: DMY,
  DK: DMY,
  ES: DMY,
  FI: DMY,
  FR: DMY,
  GB: DMY,
  HU: YMD,
  IE: DMY,
  LI: DMY,
  LT: YMD,
  LU: DMY,
  NL: DMY,
  NO: DMY,
  PL: DMY,
  SE: YMD,
  SK: DMY,
  US: MDY,
};

export const checkTaxIdForCountry: ICheckTaxIdByCountry = {
  AT: true,
  BE: true,
  CA: true,
  CH: true,
  CZ: false,
  DE: true,
  DK: true,
  ES: true,
  FI: true,
  FR: true,
  GB: true,
  HU: false,
  IE: true,
  LI: true,
  LT: true,
  LU: true,
  NL: true,
  NO: true,
  PL: true,
  SE: true,
  SK: false,
  US: false,
};

export const getFederalStateValues = (locale: ILocale): ISelectOption[] => {
  const states = federalStates[locale.id];
  return Object.keys(states).map((key: string) => ({
    id: key,
    value: key,
    label: states[key],
  }));
};
