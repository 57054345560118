/** @jsx jsx */
import { Text, jsx, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';

import { IPropsUiLibBase, IPropsStyled } from '@bemer/types';
import { BemLogo } from '../Logo';
import { BemFlex } from '../Flex';
import { BemLink } from '../Link';
import { BemText } from '../Text';

interface IGenericLink {
  label: string;
  key: string;
  target: '' | '_blank' | undefined;
}

export interface ILinkListLink extends IGenericLink {
  type: 'link';
  url: string;
}

export interface ILinkListInternalLink extends IGenericLink {
  type: 'internalLink';
  reference: {
    slug: {
      current: string;
    };
  };
}

export interface ILinkList {
  title: string;
  links: (ILinkListLink | ILinkListInternalLink)[];
}

export interface IPropsBemFooter extends IPropsUiLibBase {
  copyText: string;
  linkLists?: ILinkList[];
  versionNumber?: string;
}

const AboutBemerWrapper = styled.div`
  max-width: 250px;
  margin-top: -16px;
`;

const LogoWrapper = styled.div`
  margin-bottom: -3px;
`;

const StyledText = styled(BemText)`
  && {
    font-weight: bold;
    margin-bottom: 15px;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  margin-bottom: 12.5px;
`;

const MainBemFlex = styled(BemFlex)`
  && {
    justify-content: space-between;
    margin-bottom: 91px;
  }
`;

const FooterBemFlex = styled(BemFlex)`
  justify-content: space-between;
  align-items: center;
`;

const StyledTextVersionNumber = styled(BemText)`
  && {
    opacity: 0.4;
    font-size: 14px;
  }
`;

const StyledTextCopyRight = styled(BemText)`
  && {
    opacity: 0.6;
  }
`;

const AboutBemer = ({ copyText }: IPropsBemFooter): JSX.Element => (
  <AboutBemerWrapper>
    <LogoWrapper>
      <BemLogo />
    </LogoWrapper>
    <Text variant="muted">{copyText}</Text>
  </AboutBemerWrapper>
);

const LinkList = ({ title, links }: ILinkList): JSX.Element => (
  <div>
    <StyledText>{title}</StyledText>
    <StyledList>
      {links.map((props) => {
        if (props.type === 'internalLink') {
          const { label, key, target, reference } = props;
          return (
            <StyledListItem key={key}>
              <BemLink
                to={reference.slug?.current}
                target={target}
                linkColor="text"
              >
                {label}
              </BemLink>
            </StyledListItem>
          );
        }
        const { label, key, url } = props;
        return (
          <StyledListItem key={key}>
            <BemLink to={url} linkColor="text">
              {label}
            </BemLink>
          </StyledListItem>
        );
      })}
    </StyledList>
  </div>
);

const StyledFooter = styled.footer`
  color: ${({ theme }: IPropsStyled) => theme.colors?.darkGray};
  background-color: ${({ theme }: IPropsStyled) => theme.colors?.paleGrayTwo};
  padding: 75px 80px 40px;
`;
export const BemFooter = ({
  copyText,
  linkLists = [],
  versionNumber = 'testing.bemergroup.com',
  rawSx,
}: IPropsBemFooter): JSX.Element => {
  const context = useThemeUI();
  const { theme } = context;
  return (
    <StyledFooter theme={theme} sx={rawSx}>
      <MainBemFlex>
        <AboutBemer copyText={copyText} />
        {linkLists.map(({ title, links }: ILinkList) => (
          <LinkList key={title} title={title} links={links} />
        ))}
      </MainBemFlex>
      <FooterBemFlex>
        <StyledTextVersionNumber>{versionNumber}</StyledTextVersionNumber>
        <StyledTextCopyRight>&copy; 2020 Bemer Group</StyledTextCopyRight>
      </FooterBemFlex>
    </StyledFooter>
  );
};
