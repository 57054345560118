/** @jsx jsx */
import { jsx } from 'theme-ui';
import { IPropsUiLibBase } from '@bemer/types';
import { BemLogo } from '../Logo/BemLogo';
import { BemSpinner } from './BemSpinner';

export const BemSpinnerWithLogo = ({ rawSx }: IPropsUiLibBase): JSX.Element => {
  return (
    <BemSpinner size="large" rawSx={rawSx}>
      <BemLogo rawSx={{ width: '90px', color: 'brand' }} />
    </BemSpinner>
  );
};
